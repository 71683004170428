import { FC, Fragment } from 'react';

import { Dialog, Grid, Table, TableBody, TableContainer } from '@mui/material';

import { PopoverWrapper } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import {
  CustomTableHeader,
  CustomTablePagination,
  TableFiltersPanel,
  TableNoData,
  TableSkeleton,
} from 'components/Table';

import { ConsultationTableRow, FiltersMenuContent } from './components';
import { AddConsultationDialogWindowForm } from './components';
import { CONSULTATIONS_TABLE_HEADERS } from './constants';
import { useConsultationsState } from './useConsultations.state';

export const Consultations: FC = () => {
  let isLoading = false;

  const {
    isFiltersMenuOpen,
    closeFiltersMenu,
    openFilterMenu,
    page,
    total,
    rowsPerPage,
    onChangePageHandler,
    onChangeRowsPerPage,
    isOpenAddConsultationDialogWindow,
    openAddConsultationDialogWindow,
    closeAddConsultationDialogWindow,
  } = useConsultationsState();

  return (
    <Fragment>
      <Grid item xs={12}>
        <TableFiltersPanel
          onOpenFilterMenuHandler={openFilterMenu}
          searchInputProps={{
            placeholder: 'Type to Search',
          }}
          actionButtonProps={{
            children: 'Start Consultations',
            onClick: openAddConsultationDialogWindow,
          }}
        />

        <TableContainer>
          <Scrollbar>
            <Table size="medium" sx={{ minWidth: 600 }}>
              <CustomTableHeader headLabel={CONSULTATIONS_TABLE_HEADERS} onClickAddColumnButtonHandler={() => {}} />

              <TableBody>
                {isLoading ? <TableSkeleton cellsAmount={3} /> : <ConsultationTableRow id="123" />}
                <TableNoData isNotFound={!isLoading && !1} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <CustomTablePagination
          count={total}
          page={page - 1}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePageHandler}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Grid>

      <PopoverWrapper
        open={isFiltersMenuOpen}
        handleClosePopover={closeFiltersMenu}
        title="Filter by Consultations"
        variant="filter"
      >
        <FiltersMenuContent appliedFilters={{ isShowDeactivated: false }} onApplyFilters={() => {}} />
      </PopoverWrapper>

      <Dialog
        open={isOpenAddConsultationDialogWindow}
        onClose={closeAddConsultationDialogWindow}
        PaperProps={{
          sx: { width: 1, maxWidth: 750 },
        }}
      >
        <AddConsultationDialogWindowForm onCloseDialogHandler={closeAddConsultationDialogWindow} />
      </Dialog>
    </Fragment>
  );
};
