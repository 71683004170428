import { FC, memo, useCallback } from 'react';

import { Divider, Stack } from '@mui/material';
import { DictionaryItem } from 'apiServices';
import { useFormContext } from 'react-hook-form';

import { FormHeader, FormItem } from 'components/Form';
import { RHFAutocompleteField, RHFPhoneTextField, RHFTextField } from 'components/HookForm';
import { useGeoData } from 'hooks/useGeoData';

import { PATIENT_CONTACT_ID } from '../../constants';
import { EditPatientFormSchema } from './form.config';
import { AutocompleteOption, PatientContactCardProps } from './types';

export const PatientContactCard: FC<PatientContactCardProps> = memo(({ cardRef }) => {
  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext<EditPatientFormSchema>();

  const currentHomeAddressCountryValue = watch('contact.homeAddress.countryIsoCode');
  const currentHomeAddressStateValue = watch('contact.homeAddress.stateIsoCode');

  const currentBillingAddressCountryValue = watch('contact.billingAddress.countryIsoCode');
  const currentBillingAddressStateValue = watch('contact.billingAddress.stateIsoCode');

  const {
    countries,
    countryStates,
    onChangeCityInputValueHandler,
    onChangeCountryInputValueHandler,
    onSelectCountryHandler,
    stateCities,
    isCountriesLoading,
    isCountryStatesLoading,
    isStateCitiesLoading,
    onSelectStateHandler,
    country,
  } = useGeoData({
    currentStateISOcode: currentHomeAddressStateValue,
    currentCountryISOcode: currentHomeAddressCountryValue,
  });

  const {
    countries: billingAddressCountries,
    countryStates: billingAddressStates,
    onChangeCityInputValueHandler: onChangeBillingAddressCityInputValueHandler,
    onChangeCountryInputValueHandler: onChangeBillingAddressCountryInputValueHandler,
    onSelectCountryHandler: onSelectBillingAddressCountryHandler,
    stateCities: billingAddressCities,
    isCountriesLoading: isBillingAddressCountriesLoading,
    isCountryStatesLoading: isBillingAddressStatesLoading,
    isStateCitiesLoading: isBillingAddressCitiesLoading,
    onSelectStateHandler: onSelectBillingAddressStateHandler,
    country: billingAddressCountry,
  } = useGeoData({
    currentStateISOcode: currentBillingAddressStateValue,
    currentCountryISOcode: currentBillingAddressCountryValue,
  });

  const onChangeCountryHomeAddressAutocompleteHandler = useCallback(
    (option: AutocompleteOption<DictionaryItem>) => {
      onSelectCountryHandler(option);
      currentHomeAddressStateValue && setValue('contact.homeAddress.stateIsoCode', '');
    },
    [currentHomeAddressStateValue, setValue]
  );

  const onChangeCityHomeAddressAutocompleteHandler = useCallback(
    (option: AutocompleteOption<DictionaryItem>) => {
      onSelectStateHandler(option);
      watch('contact.homeAddress.cityId') && setValue('contact.homeAddress.cityId', '');
    },
    [watch, setValue]
  );

  const onChangeBillingAddressCountryAutocompleteHandler = useCallback(
    (option: AutocompleteOption<DictionaryItem>) => {
      onSelectBillingAddressCountryHandler(option);
      currentBillingAddressStateValue && setValue('contact.billingAddress.stateIsoCode', '');
    },
    [currentBillingAddressStateValue, onSelectBillingAddressCountryHandler, setValue]
  );

  const billingAddressCountriesOptions =
    currentBillingAddressCountryValue && billingAddressCountry && !countries?.items.length
      ? [billingAddressCountry]
      : billingAddressCountries?.items;

  const countriesOptions =
    currentHomeAddressCountryValue && country && !countries?.items.length ? [country] : countries?.items;

  return (
    <>
      <Stack ref={cardRef} id={PATIENT_CONTACT_ID} sx={{ p: { xs: 2, sm: 2.5, md: 3, lg: 3.5 } }}>
        <FormHeader title="Patient contact" subtitle="Fill out the details for the contact’s." />

        <Stack>
          <FormItem title="Email">
            <RHFTextField
              register={register}
              registerName="contact.email"
              registerErrors={errors.contact?.email?.message}
              fullWidth
              placeholder="Enter Email"
            />
          </FormItem>

          <FormItem title="Home address">
            <Stack spacing={2} width={1}>
              <RHFAutocompleteField
                control={control}
                name="contact.homeAddress.countryIsoCode"
                placeholder="Select Country"
                onChangeHandler={onChangeCountryHomeAddressAutocompleteHandler}
                onInputChange={onChangeCountryInputValueHandler}
                loading={isCountriesLoading}
                options={countriesOptions}
                valueKey="isoCode"
                labelKey="isoName"
              />

              <RHFAutocompleteField
                control={control}
                name="contact.homeAddress.stateIsoCode"
                placeholder={!currentHomeAddressCountryValue ? 'Select country first' : 'State'}
                onChangeHandler={onChangeCityHomeAddressAutocompleteHandler}
                disabled={!currentHomeAddressCountryValue}
                options={countryStates}
                loading={isCountryStatesLoading}
                valueKey="isoCode"
                labelKey="isoName"
              />

              <RHFAutocompleteField
                control={control}
                name="contact.homeAddress.cityId"
                placeholder={!currentHomeAddressStateValue ? 'Select state first' : 'City'}
                onInputChange={onChangeCityInputValueHandler}
                disabled={!currentHomeAddressStateValue}
                options={stateCities}
                loading={isStateCitiesLoading}
                valueKey="id"
                labelKey="name"
              />
            </Stack>
          </FormItem>

          <FormItem title="Billing address">
            <Stack spacing={2} width={1}>
              <RHFAutocompleteField
                control={control}
                name="contact.billingAddress.countryIsoCode"
                placeholder="Select country"
                onChangeHandler={onChangeBillingAddressCountryAutocompleteHandler}
                onInputChange={onChangeBillingAddressCountryInputValueHandler}
                options={billingAddressCountriesOptions}
                loading={isBillingAddressCountriesLoading}
                valueKey="isoCode"
                labelKey="isoName"
              />

              <RHFAutocompleteField
                control={control}
                name="contact.billingAddress.stateIsoCode"
                placeholder={!currentBillingAddressCountryValue ? 'Select country first' : 'State'}
                onChangeHandler={onSelectBillingAddressStateHandler}
                disabled={!currentBillingAddressCountryValue}
                loading={isBillingAddressStatesLoading}
                options={billingAddressStates}
                valueKey="isoCode"
                labelKey="isoName"
              />

              <RHFAutocompleteField
                control={control}
                name="contact.billingAddress.cityId"
                placeholder={!currentBillingAddressStateValue ? 'Select state first' : 'City'}
                onInputChange={onChangeBillingAddressCityInputValueHandler}
                disabled={!currentBillingAddressStateValue}
                options={billingAddressCities}
                loading={isBillingAddressCitiesLoading}
                valueKey="id"
                labelKey="name"
              />
            </Stack>
          </FormItem>

          <FormItem title="Phone number">
            <RHFPhoneTextField
              control={control}
              registerErrors={errors.contact}
              registerName="contact.phone"
              placeholder="Enter phone number"
              country="us"
              MUITextFieldProps={{ fullWidth: true, sx: { width: 1 } }}
            />
          </FormItem>

          <FormItem title="Website" isDivider={false}>
            <RHFTextField
              register={register}
              registerName="contact.website"
              registerErrors={errors.contact?.website?.message}
              fullWidth
              placeholder="Enter website"
            />
          </FormItem>
        </Stack>
      </Stack>

      <Divider />
    </>
  );
});
