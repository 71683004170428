import { FC, memo, useEffect } from 'react';

import { usePatientEnums } from 'hooks/usePatientEnums';

import { PatientContactCard } from './PatientContactCard';
import { PatientDemographicsCard } from './PatientDemographicsCard';
import { EditPatientFormProps } from './types';

export const EditPatientForm: FC<EditPatientFormProps> = memo(({ refs, fetchPatient, patientId }) => {
  const { isAllPatientEnumsLoading, fetchAllPatientEnums, patientEnums } = usePatientEnums();

  useEffect(() => {
    fetchAllPatientEnums();
  }, []);

  return (
    <>
      <PatientDemographicsCard
        cardRef={refs?.[0]}
        ethnicGroups={patientEnums?.ethnicGroups}
        genders={patientEnums?.genders}
        personTitles={patientEnums?.personTitles}
        isAllPatientEnumsLoading={isAllPatientEnumsLoading}
      />

      <PatientContactCard cardRef={refs?.[1]} />
      {/* TODO: Add note card when BE is ready */}
      {/* <NoteCard fetchPatient={fetchPatient} patientId={patientId} cardRef={refs?.[2]} /> */}
    </>
  );
});
