import { FC } from 'react';

import { RefreshRounded } from '@mui/icons-material';
import { Box, Card, Grid, Typography } from '@mui/material';

import { EmptyStateWithAction } from 'components/EmptyState';
import { FormSkeleton, SideNavCardSkeleton } from 'components/Form';
import { Icon } from 'components/Icon';
import { MDTabs } from 'components/MDTabs';
import { EntityHeader, EntityHeaderSkeleton } from 'components/Widgets';

import { Concerns, Consultations, Medical, PatientProfile, Profile } from './TabContentPages';
import { usePatientViewState } from './usePatientView.state';

function renderTabValueContent(tabValue: number) {
  switch (tabValue) {
    case 0:
      return <Profile />;
    case 1:
      return <Medical />;
    case 3:
      return <Concerns />;
    case 4:
      return <Consultations />;
    default:
      return <PatientProfile />;
  }
}

export const PatientView: FC = () => {
  const {
    isLoading,
    age,
    person,
    formatedBirthDate,
    handleSetTabValue,
    tabValue,
    fullName,
    fullNameWidthMiddleName,
    tabs,
    isNoPatient,
    patient,
    onGetPatientHandler,
    onUploadAvatarHandler,
    onDeleteAvatarHandler,
    avatarSrc,
    isCanEditAvatar,
  } = usePatientViewState();

  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        ...(isNoPatient && {
          flex: 1,
        }),
      }}
    >
      <Card sx={{ width: 1 }}>
        <Grid
          container
          sx={{
            ...(isNoPatient && {
              height: 1,
            }),
          }}
        >
          {isNoPatient ? (
            <EmptyStateWithAction
              title="Failed to get patient"
              subtitle="Please use the button below to try once again."
              actionButtonProps={{
                title: 'Get patient',
                onClickHandler: onGetPatientHandler,
                buttonProps: { startIcon: <RefreshRounded fontSize="medium" /> },
              }}
            />
          ) : (
            <>
              <Grid item xs={12}>
                {isLoading ? (
                  <EntityHeaderSkeleton tabsAmount={tabs.length} />
                ) : (
                  <EntityHeader
                    title={person?.middleName ? fullNameWidthMiddleName : fullName}
                    subtitle={
                      <>
                        <Icon
                          type="calendar"
                          sx={{
                            color: theme => theme.palette.grey[500],
                          }}
                        />

                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: 14,
                            color: theme => theme.palette.grey[500],
                          }}
                        >
                          {`${formatedBirthDate} (${age})`}
                        </Typography>
                      </>
                    }
                    id={patient?.id}
                    avatarProps={{
                      src: avatarSrc,
                      initialPhotoUrl: patient?.photoUrl || '',
                      onCreateFileHandler: onUploadAvatarHandler,
                      onDeleteFileHandler: onDeleteAvatarHandler,
                      isDisabled: !isCanEditAvatar,
                    }}
                    tabs={
                      <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }} width={1} mt={1}>
                        <MDTabs
                          variant="scrollable"
                          scrollButtons="auto"
                          allowScrollButtonsMobile
                          value={tabValue}
                          tabsData={tabs}
                          handleSetTabValue={handleSetTabValue}
                        />
                      </Box>
                    }
                  />
                )}
              </Grid>

              {isLoading ? (
                <>
                  <Grid item xs={12} md={2}>
                    <SideNavCardSkeleton />
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <FormSkeleton rows={4} />
                    <FormSkeleton rows={5} />
                  </Grid>
                </>
              ) : (
                renderTabValueContent(tabValue)
              )}
            </>
          )}
        </Grid>
      </Card>
    </Box>
  );
};
