import { FC, memo, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { GetPracticesParams, createExpert } from 'apiServices';
import { Resolver, useForm } from 'react-hook-form';

import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFAutocompleteField, RHFTextField } from 'components/HookForm';
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper';
import { UserRoleRenderOption } from 'components/MDAutocomplete';
import { Scrollbar } from 'components/ScrollBar';
import { ToastType, notice } from 'components/ToastNotification';
import { useOptions, usePracticeEnums, useUserProfile } from 'hooks';
import { formErrorHandler } from 'utils/errorHanders';

import { CreateUserFormSchema, createUserValidationSchema, defaultValues } from './form.config';

type AddUserDialogWindowFormProps = {
  onCloseDialogHandler: VoidFunction;
  onGetUsersHandler: (data: { params?: GetPracticesParams; isDefaultPage?: boolean }) => Promise<void>;
};

export const AddUserDialogWindowForm: FC<AddUserDialogWindowFormProps> = memo(
  ({ onGetUsersHandler, onCloseDialogHandler }) => {
    const {
      userRoles: { isRoleAdmin },
      expertProfile,
    } = useUserProfile();

    const {
      control,
      register,
      handleSubmit,
      setError,
      formState: { isValid, isSubmitting, errors },
    } = useForm<CreateUserFormSchema>({
      resolver: yupResolver(createUserValidationSchema) as Resolver<CreateUserFormSchema>,
      mode: 'onTouched',
      defaultValues: {
        ...defaultValues,
        ...(!isRoleAdmin && {
          practiceId: expertProfile?.practice?.id || '',
        }),
      },
    });

    const onFormSubmitHandler = handleSubmit(async formData => {
      const { role, person, practiceId } = formData;
      try {
        await createExpert({
          practiceId,
          role,
          person: {
            firstName: person.firstName,
            lastName: person.lastName,
            ...(person.middleName && {
              middleName: person.middleName,
            }),
            contact: {
              email: person.contact.email,
            },
          },
        });
        onCloseDialogHandler();
        notice(ToastType.SUCCESS, 'User has been successfully created!');

        await onGetUsersHandler({ isDefaultPage: true, params: { page: 1 } });
      } catch (error) {
        formErrorHandler({ error, config: { formError: { setError } } });
      }
    });

    const isSubmitButtonDisabled = !isValid || isSubmitting;

    const { practiceRolesEnum, fetchPracticeRolesEnum, isPracticeRolesEnumLoading } = usePracticeEnums();

    const userRoles = practiceRolesEnum.slice(3);

    const { debouncedPracticeSearchValue, fetchPractices, onChangePracticeInputValueHandler, practicesState } =
      useOptions({
        isImmediateFetchPractices: isRoleAdmin ?? false,
      });

    useEffect(() => {
      fetchPracticeRolesEnum();
    }, []);

    return (
      <FormDialogWindowContent
        onFormSubmitHandler={onFormSubmitHandler}
        headerProps={{ title: 'Add User', onClickCancelButtonHandler: onCloseDialogHandler }}
        actionProps={{
          approveButtonProps: { disabled: isSubmitButtonDisabled, isLoading: isSubmitting },
        }}
      >
        <Scrollbar>
          <Stack spacing={2} width={1} maxHeight={{ xs: 350, sm: 1 }} pb={1}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              gap={2}
              sx={{
                '.MuiStack-root': {
                  width: 1,
                },
              }}
            >
              <RHFTextField
                register={register}
                registerName="person.firstName"
                registerErrors={errors.person?.firstName?.message}
                fullWidth
                required
                label="First Name"
                placeholder="Enter First Name"
              />

              <RHFTextField
                register={register}
                registerName="person.lastName"
                registerErrors={errors?.person?.lastName?.message}
                fullWidth
                required
                placeholder="Enter Last Name"
                label="Last Name"
              />
            </Stack>

            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              gap={2}
              sx={{
                '.MuiStack-root': {
                  width: 1,
                },
              }}
            >
              <RHFTextField
                register={register}
                registerName="person.contact.email"
                registerErrors={errors?.person?.contact?.email?.message}
                fullWidth
                required
                placeholder="Enter Email"
                label="Email"
              />

              <RHFAutocompleteField
                control={control}
                name="role"
                isRequired
                label="Role"
                placeholder="Select Role"
                options={userRoles}
                loading={isPracticeRolesEnumLoading}
                renderOption={UserRoleRenderOption}
                valueKey="value"
                labelKey="value"
              />
            </Stack>

            {isRoleAdmin && (
              <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} width={{ xs: 1, sm: 0.5 }} pr={1}>
                <InfiniteScrollWrapper
                  searchQuery={debouncedPracticeSearchValue}
                  totalPages={practicesState.totalPages}
                  fetchItemsHandler={fetchPractices}
                >
                  {({ ref }) => (
                    <RHFAutocompleteField
                      control={control}
                      name="practiceId"
                      isRequired
                      label="Practice"
                      placeholder="Select Practice"
                      options={practicesState.items}
                      onInputChange={onChangePracticeInputValueHandler}
                      loading={practicesState.isLoading}
                      valueKey="id"
                      labelKey="name"
                      lastElementRef={ref}
                    />
                  )}
                </InfiniteScrollWrapper>
              </Stack>
            )}
          </Stack>
        </Scrollbar>
      </FormDialogWindowContent>
    );
  }
);
