import { useCallback, useEffect, useState } from 'react';

import { GetExpertsParams, deleteExpert, getExperts } from 'apiServices';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { ToastType, notice } from 'components/ToastNotification';
import { useActionDialogManagement, useBoolean, useDebounce, usePopover, useTable } from 'hooks';
import { backendErrorHandler } from 'utils/errorHanders';

import { FiltersInitialState } from './types';

export const FiltersInitialStateValue: FiltersInitialState = {
  isShowDeactivated: false,
  office_id: null,
  practice_id: null,
  roles: [],
};

export const useUsersState = () => {
  const {
    experts: { items, total },
    setExperts,
    expertProfileId,
    activateExpert,
    deactivateExpert,
  } = useAppStore(
    store => ({
      experts: store.experts,
      setExperts: store.setExperts,
      expertProfileId: store.expertProfile?.id,
      activateExpert: store.activateExpert,
      deactivateExpert: store.deactivateExpert,
    }),
    shallow
  );

  const { page, rowsPerPage, onChangeRowsPerPage, setPage, isResettingPage, resetPageHandler } = useTable();

  const [isOpenAddUserDialogWindow, openAddUserDialogWindow, closeAddUserDialogWindow] = useBoolean();

  const [isLoading, setIsLoading] = useState(true);

  const onGetUsersHandler = async ({
    params,
    isDefaultPage,
  }: {
    params?: GetExpertsParams;
    isDefaultPage?: boolean;
  }) => {
    !isLoading && setIsLoading(true);

    const roles = appliedFilters.roles?.map(role => role?.value) || [];

    try {
      const { data } = await getExperts({
        ...(debouncedSearchValue && {
          search: debouncedSearchValue,
        }),
        ...(appliedFilters.office_id && {
          office_id: appliedFilters.office_id,
        }),
        ...(appliedFilters.practice_id && {
          practice_id: appliedFilters.practice_id,
        }),
        is_active: appliedFilters.isShowDeactivated ? false : true,
        page,
        size: rowsPerPage,
        roles,
        ...params,
      });
      setExperts(data);

      if (isDefaultPage && page !== 1) setPage(1);
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get users, please try again!' } });
    } finally {
      setIsLoading(false);
    }
  };

  const [searchValue, setSearchValue] = useState('');

  const debouncedSearchValue = useDebounce(searchValue, 200);

  const onChangeSearchValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    isResettingPage.current = true;
  };

  const {
    handleClosePopover: closeFiltersMenu,
    handleOpenPopover: openFilterMenu,
    openPopover: isFiltersMenuOpen,
  } = usePopover();

  const onChangePageHandler = async (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);
    await onGetUsersHandler({ params: { page: newPage } });
  };

  const [appliedFilters, setAppliedFilters] = useState<FiltersInitialState>(FiltersInitialStateValue);

  const onApplyFilters = useCallback((newFilters: FiltersInitialState) => {
    setAppliedFilters(newFilters);
    isResettingPage.current = true;
    closeFiltersMenu();
  }, []);

  const [isOpenDeleteDialogWindow, openDeleteDialogWindow, closeDeleteDialogWindow] = useBoolean();

  const [selectedItem, setSelectedItem] = useState({ name: '', id: '' });

  const onClickDeleteMenuItemHandler = (data: { name: string; id: string }) => {
    openDeleteDialogWindow();
    setSelectedItem(data);
  };

  const {
    actionState,
    closeActivateDialog,
    closeDeactivateDialog,
    isActivateDialogOpen,
    isActionPending,
    isDeactivateDialogOpen,
    onClickActionOptionHandler,
    setIsActionPending,
  } = useActionDialogManagement();

  const onDeactivateUserHandler = async () => {
    setIsActionPending(true);

    const { id } = actionState;

    const resp = await deactivateExpert(id);

    setIsActionPending(false);

    if (resp) {
      closeDeactivateDialog();
      await onGetUsersHandler({ isDefaultPage: true, params: { page: 1 } });
    }
  };

  const onActivateUserHandler = async () => {
    setIsActionPending(true);

    const { id } = actionState;

    const resp = await activateExpert(id);

    setIsActionPending(false);

    if (resp) {
      closeActivateDialog();
      await onGetUsersHandler({ isDefaultPage: true, params: { page: 1 } });
    }
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const onDeleteUserHandler = async (_: React.MouseEvent<HTMLElement>) => {
    setIsDeleting(true);
    const { id } = selectedItem;
    try {
      await deleteExpert(id);

      notice(ToastType.SUCCESS, 'User has been successfully deleted!');

      await onGetUsersHandler({ isDefaultPage: true, params: { page: 1 } });

      closeDeleteDialogWindow();
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to delete user, please try again!',
        },
      });
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    onGetUsersHandler({ params: { ...(isResettingPage.current && { page: 1 }) } });
    resetPageHandler();
  }, [debouncedSearchValue, rowsPerPage, appliedFilters]);

  return {
    items,
    isLoading,
    onGetUsersHandler,
    searchValue,
    onChangeSearchValueHandler,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    page,
    rowsPerPage,
    onChangeRowsPerPage,
    total,
    onChangePageHandler,
    onApplyFilters,
    appliedFilters,
    onClickDeleteMenuItemHandler,
    closeDeleteDialogWindow,
    isOpenDeleteDialogWindow,
    selectedItem,
    onDeleteUserHandler,
    isDeleting,
    onActivateUserHandler,
    onDeactivateUserHandler,
    onClickActionOptionHandler,
    actionState,
    isDeactivateDialogOpen,
    isActivateDialogOpen,
    isActionPending,
    closeActivateDialog,
    closeDeactivateDialog,
    expertProfileId,
    isOpenAddUserDialogWindow,
    openAddUserDialogWindow,
    closeAddUserDialogWindow,
  };
};
