import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import { ROUTES } from 'constants/routes';
import { USER_ROLES } from 'types/enums';

import {
  createBreadcrumb,
  getBreadCrumbName,
  mapGLProductStateToProps,
  mapGLServiceStateToProps,
  mapGLalbumStateToProps,
  mapGLimagePairParentRouteStateToProps,
  mapGLimagePairStateToProps,
  mapPLProductStateToProps,
  mapPLServiceStateToProps,
  mapPLalbumStateToProps,
  mapPLimagePairParentRouteStateToProps,
  mapPLimagePairStateToProps,
  mapPatientStateToProps,
  mapPracticeStateToProps,
  mapPromptStateToProps,
  mapTagCategoryStateToProps,
  mapUserStateToProps,
} from './breadcrumbs.utils';

export const DEFAULT_EXCLUDED_ROUTES = [
  ROUTES.patients,
  ROUTES.practiceManagement,
  '/catalog/:id',
  'before-and-after-galleries/gl',
  'before-and-after-galleries/pl',
  'tag-management/gl',
  'tag-management/pl',
];

export const ROLE_BASED_ROUTE_INCLUSIONS: Partial<Record<USER_ROLES, string[]>> = {
  [USER_ROLES.ROLE_ADMIN]: [ROUTES.practiceManagement],
};

export const ROLE_BASED_ROUTE_EXCLUSIONS: Partial<Record<USER_ROLES, string[]>> = {
  [USER_ROLES.ROLE_AI_TESTER]: [ROUTES.home],
};

export const getBreadcrumbsConfig = (userRole?: USER_ROLES) => {
  if (!userRole) return DEFAULT_EXCLUDED_ROUTES;

  const roleExclusions = ROLE_BASED_ROUTE_EXCLUSIONS[userRole] || [];
  const roleInclusions = ROLE_BASED_ROUTE_INCLUSIONS[userRole] || [];

  return [...DEFAULT_EXCLUDED_ROUTES, ...roleExclusions].filter(route => !roleInclusions.includes(route));
};

export const DYNAMIC_BREADCRUMBS = {
  patient: createBreadcrumb(mapPatientStateToProps),
  practice: createBreadcrumb(mapPracticeStateToProps),
  glTagCategoryProfile: createBreadcrumb(mapTagCategoryStateToProps),
  plTagCategoryProfile: createBreadcrumb(mapTagCategoryStateToProps),
  userProfile: createBreadcrumb(mapUserStateToProps),
  glServiceProfile: createBreadcrumb(mapGLServiceStateToProps),
  plServiceProfile: createBreadcrumb(mapPLServiceStateToProps),
  glProductProfile: createBreadcrumb(mapGLProductStateToProps),
  plProductProfile: createBreadcrumb(mapPLProductStateToProps),
  glAlbumProfile: createBreadcrumb(mapGLalbumStateToProps),
  plAlbumProfile: createBreadcrumb(mapPLalbumStateToProps),
  glAlbumPhotosProfileParentRoute: createBreadcrumb(mapGLimagePairParentRouteStateToProps),
  plAlbumPhotosProfileParentRoute: createBreadcrumb(mapPLimagePairParentRouteStateToProps),
  glAlbumPhotosProfile: createBreadcrumb(mapGLimagePairStateToProps),
  plAlbumPhotosProfile: createBreadcrumb(mapPLimagePairStateToProps),
  prompt: createBreadcrumb(mapPromptStateToProps),
};

export const BREADCRUMBS_ROUTES: BreadcrumbsRoute<string>[] = [
  { path: ROUTES.home, breadcrumb: 'Home' },
  { path: ROUTES.beforeAfter, breadcrumb: 'Before & After Galleries' },
  {
    path: ROUTES.viewPrompt,
    breadcrumb: getBreadCrumbName,
  },

  {
    children: [
      {
        path: ROUTES.patientProfile,
        breadcrumb: DYNAMIC_BREADCRUMBS.patient,
      },
      {
        path: ROUTES.patientConcernProfile,
        breadcrumb: 'Concern',
      },
    ],
  },

  {
    path: ROUTES.practiceManagementProfile,
    breadcrumb: DYNAMIC_BREADCRUMBS.practice,
  },

  {
    path: ROUTES.tagManagementGLTagCategoryProfile,
    breadcrumb: DYNAMIC_BREADCRUMBS.glTagCategoryProfile,
  },

  {
    path: ROUTES.tagManagementPLTagCategoryProfile,
    breadcrumb: DYNAMIC_BREADCRUMBS.glTagCategoryProfile,
  },

  {
    path: ROUTES.usersProfile,
    breadcrumb: DYNAMIC_BREADCRUMBS.userProfile,
  },

  {
    path: ROUTES.catalogGLServiceProfile,
    breadcrumb: DYNAMIC_BREADCRUMBS.glServiceProfile,
  },
  {
    path: ROUTES.catalogPLServiceProfile,
    breadcrumb: DYNAMIC_BREADCRUMBS.plServiceProfile,
  },
  {
    path: ROUTES.catalogGLProductProfile,
    breadcrumb: DYNAMIC_BREADCRUMBS.glProductProfile,
  },

  {
    path: ROUTES.catalogPLProductProfile,
    breadcrumb: DYNAMIC_BREADCRUMBS.plProductProfile,
  },

  {
    children: [
      {
        path: ROUTES.beforeAfterGLProfile,
        breadcrumb: props => {
          return props.location.pathname === props.match.pathname
            ? DYNAMIC_BREADCRUMBS.glAlbumProfile(props)
            : DYNAMIC_BREADCRUMBS.glAlbumPhotosProfileParentRoute(props);
        },
      },
      { path: ROUTES.beforeAfterPhotosGLProfile, breadcrumb: DYNAMIC_BREADCRUMBS.glAlbumPhotosProfile },
    ],
  },

  {
    children: [
      {
        path: ROUTES.beforeAfterPLProfile,
        breadcrumb: props => {
          return props.location.pathname === props.match.pathname
            ? DYNAMIC_BREADCRUMBS.plAlbumProfile(props)
            : DYNAMIC_BREADCRUMBS.plAlbumPhotosProfileParentRoute(props);
        },
      },
      { path: ROUTES.beforeAfterPhotosPLProfile, breadcrumb: DYNAMIC_BREADCRUMBS.plAlbumPhotosProfile },
    ],
  },
];
