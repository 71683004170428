import { AutocompleteProps, CSSObject, ComponentsOverrides, ComponentsProps, Theme, alpha } from '@mui/material';

import borders from 'assets/theme/base/borders';
import boxShadows from 'assets/theme/base/boxShadows';
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import pxToRem from 'assets/theme/functions/pxToRem';
import { Icon } from 'components/Icon';

const { xs } = boxShadows;
const { size } = typography;
const { primary, text, white, transparent, dark, grey } = colors;
const { borderRadius } = borders;

const rootStyle = (ownerState: AutocompleteProps<any, any, any, any, 'div'> & Record<string, unknown>) => {
  const defaultStyles: CSSObject = {
    borderRadius: 8,
    '& fieldset': {
      borderWidth: 1,
    },

    '& .Mui-disabled': {
      backgroundColor: grey[100],
    },

    '&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot, &.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot':
      {
        paddingRight: 42,
      },

    '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
      paddingRight: 64,
    },

    '& .MuiAutocomplete-inputRoot .MuiInput-input': {
      padding: 0,
    },

    '& .MuiInput-underline': {
      paddingLeft: 12,
    },

    ...(ownerState.size === 'medium' && {
      '& .MuiInputBase-root': {
        height: 40,
        fontSize: 14,
      },
    }),
    ...(ownerState.size === 'small' && {
      '& .MuiInputBase-root': {
        height: 36,
        paddingLeft: 8,
        fontSize: 12,
      },
      '& .MuiInputBase-sizeSmall': {
        paddingLeft: '8px !important',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
      },
    }),
    ...(ownerState.multiple && {
      '& .MuiInputBase-root': {
        height: 'unset',
        ...(ownerState.size === 'medium' && {
          minHeight: 40,
        }),
        ...(ownerState.size === 'small' && {
          minHeight: 36,
        }),
      },
    }),
  };

  return [defaultStyles];
};

const autocomplete: {
  defaultProps?: ComponentsProps['MuiAutocomplete'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAutocomplete'];
} = {
  defaultProps: {
    ChipProps: {
      deleteIcon: <Icon type="closeIcon" />,
    },
    popupIcon: <Icon type="arrowLeftIcon" sx={{ svg: { width: 20, height: 20 }, transform: 'rotate(-90deg)' }} />,
  },
  styleOverrides: {
    root: ({ ownerState }) => rootStyle(ownerState),

    popper: {
      boxShadow: xs,
      padding: `${pxToRem(4)} ${pxToRem(6)}`,
      fontSize: size.sm,
      color: text.main,
      textAlign: 'left',
      backgroundColor: `${white.main} !important`,
      borderRadius: borderRadius.lg,
      border: `${borders.borderWidth[1]} solid ${grey[300]}`,
      marginTop: '3px !important',
    },

    popupIndicator: {
      transition: 'all 0.3s',
      marginRight: 0,
    },

    paper: {
      boxShadow: 'none',
      backgroundColor: transparent.main,
    },
    listbox: {
      paddingTop: pxToRem(4),
      paddingBottom: pxToRem(4),
      '& .MuiAutocomplete-option[aria-selected="true"], .Mui-focused': {
        color: `${dark.main}`,
      },
    },

    option: {
      padding: `${pxToRem(8)} ${pxToRem(12)} !important`,
      borderRadius: borderRadius.md,
      fontSize: size.sm,
      color: dark.main,
      ':first-of-type': {
        marginTop: 0,
      },
      marginTop: 3,
      transition: 'background-color 300ms ease, color 300ms ease',

      '&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
        color: dark.main,
      },
    },

    noOptions: {
      fontSize: size.sm,
      color: dark.main,
      padding: pxToRem(4),
    },

    groupLabel: {
      color: dark.main,
    },

    loading: {
      fontSize: size.sm,
      color: dark.main,
    },

    tag: {
      alignItems: 'center',
      height: 28,
      padding: 6,
      backgroundColor: alpha(primary.main, 0.25),
      color: alpha(primary.main, 0.9),
      borderRadius: borderRadius.lg,
      maxWidth: 'calc(100% - 20%)',
      border: 0,
      ':hover': {
        backgroundColor: alpha(primary.main, 0.35),
      },

      '& .MuiChip-label': {
        fontSize: size.xs,
        textTransform: 'capitalize',
        lineHeight: 'initial',
        alignSelf: 'center',
        padding: '0 4px 0 0',
      },

      '& .MuiChip-deleteIcon': {
        color: alpha(primary.main, 0.9),
        margin: 0,
        '&:hover': {
          color: alpha(primary.main, 0.9),
        },
      },
    },
    endAdornment: {
      right: 12,
      top: 'calc(50% - 12px)',
    },
  },
};

export default autocomplete;
