import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';

const { primary, inputBorderColor, dark, grey } = colors;
const { size } = typography;
const { borderWidth } = borders;

const input = {
  styleOverrides: {
    root: {
      fontSize: size.sm,
      color: dark.main,

      '&:hover:not(.Mui-disabled, .Mui-focused, .Mui-error):before': {
        borderWidth: borderWidth[1],
        borderColor: grey[500],
      },

      '&:before': {
        borderColor: inputBorderColor,
        borderWidth: borderWidth[1],
      },

      '&:after': {
        borderColor: primary.main,
      },

      '&.MuiInput-underline.MuiInputBase-adornedStart': {
        paddingLeft: 12,
      },
    },

    input: {
      color: grey[700],
      padding: '9px 12px',
      textOverflow: 'ellipsis',
    },

    inputSizeSmall: {
      fontSize: size.xs,
      padding: '5px 12px',
    },

    multiline: {
      color: grey[700],
      padding: 0,
    },
  },
};

export default input;
