import { BASE_PRACTICE_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

type Contact = {
  id: string;
} & Timestamps &
  BaseContact;

export type ContactPerson = Person & {
  id: string;
  contact: Contact;
} & Timestamps;

export type GetPracticeContactPersonsParams = PaginationParams & {
  search: string;
};

export type CreatePracticeContactPersonPayload = Person & {
  contact: BaseContact;
};

export type UpdatePracticeContactPersonPayload = Omit<Person, 'id'> & {
  contact: BaseContact;
};

export const getPracticeContactPersons = async (practiceId: string, params: GetPracticeContactPersonsParams) =>
  await apiClient.get<ListOfItems<ContactPerson>>(`${BASE_PRACTICE_API_URL}/${practiceId}/contact_persons`, { params });

export const createPracticeContactPerson = async (practiceId: string, person: CreatePracticeContactPersonPayload) =>
  await apiClient.put<ContactPerson>(`${BASE_PRACTICE_API_URL}/${practiceId}/contact_persons`, person);

export const getPracticeContactPerson = async (practiceId: string, personId: string) =>
  await apiClient.get<ContactPerson>(`${BASE_PRACTICE_API_URL}/${practiceId}/contact_persons/${personId}`);

export const deletePracticeContactPerson = async (practiceId: string, personId: string): Promise<void> => {
  await apiClient.delete(`${BASE_PRACTICE_API_URL}/${practiceId}/contact_persons/${personId}`);
};

export const patchPracticeContactPerson = async (
  practiceId: string,
  personId: string,
  person: UpdatePracticeContactPersonPayload
) => await apiClient.patch<ContactPerson>(`${BASE_PRACTICE_API_URL}/${practiceId}/contact_persons/${personId}`, person);
