import { FC } from 'react';

import { Stack } from '@mui/material';

import { MDInput } from 'components/MDInput';

export const ConsultationOverview: FC = () => {
  return (
    <Stack width={1} p={{ xs: 3, lg: 4 }} gap={2.5}>
      <MDInput
        label="Summary"
        value="Aesthetic Results: Achieve a harmonious and natural appearance in the upper third of the face, addressing client concerns such as wrinkle reduction, brow lifting, or symmetry correction.
Functionality: Preserve or enhance facial expressions without creating a sense of stiffness or limiting mobility.
Safety of Procedure: Employ modern techniques and technologies to minimize risks and side effects, such as bruising, swelling, or infection.
Comfortable Recovery: Ensure a swift and smooth recovery process, with clear guidance to help clients return to their routine quickly.
Emotional Confidence: Instill confidence in clients regarding their decision, providing psychological support before and after the procedure.
Personalized Approach: Develop a tailored surgical plan considering individual anatomy, age, gender, and personal preferences.
Long-Lasting Results: Deliver outcomes that are durable over time, accounting for the natural aging process."
        multiline
        minRows={7}
        maxRows={10}
      />

      <MDInput
        label="Follow-up Queston"
        value="Aesthetic Results: Achieve a harmonious and natural appearance in the upper third of the face, addressing client concerns such as wrinkle reduction, brow lifting, or symmetry correction.
Functionality: Preserve or enhance facial expressions without creating a sense of stiffness or limiting mobility.
Safety of Procedure: Employ modern techniques and technologies to minimize risks and side effects, such as bruising, swelling, or infection."
        multiline
        minRows={7}
        maxRows={10}
      />
    </Stack>
  );
};
