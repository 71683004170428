import { useEffect, useState } from 'react';

import { IntersectionOptions, useInView } from 'react-intersection-observer';

export type UseInfinityScrollProps = {
  useInViewProps?: IntersectionOptions;
  defaultCurrentPage?: number;
  totalPages: number;
  fetchItemsHandler: (params?: { page: number }) => Promise<void>;
};

export const useInfinityScroll = ({
  useInViewProps,
  defaultCurrentPage = 1,
  totalPages,
  fetchItemsHandler,
}: UseInfinityScrollProps) => {
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);

  const [ref, isInView] = useInView({
    threshold: 1,
    ...useInViewProps,
  });

  useEffect(() => {
    if (isInView && currentPage < totalPages) {
      const nextPage = currentPage + 1;

      fetchItemsHandler({ page: nextPage });
      setCurrentPage(nextPage);
    }
  }, [isInView]);

  return { ref, currentPage, setCurrentPage };
};
