import { useEffect, useState } from 'react';

import { GetPromptsSearchParams, PromptItemResponse } from 'apiServices';
import { deletePrompt, getAllPrompts } from 'apiServices';
import { useAppStore } from 'store';

import { ToastType, notice } from 'components/ToastNotification';
import { useActionDialogManagement, useBoolean, useDebounce, useRouter, useUserProfile } from 'hooks';
import { usePopover } from 'hooks/usePopover';
import { useTable } from 'hooks/useTable';
import { backendErrorHandler } from 'utils/errorHanders';

export const usePromptLibraryState = () => {
  const {
    userRoles: { isRoleAItester },
  } = useUserProfile();

  const { navigate } = useRouter();

  const {
    prompts: { items, pagination },
    setPrompts,
  } = useAppStore(store => ({ prompts: store.prompts, setPrompts: store.setPrompts }));

  const { page, rowsPerPage, setPage, onChangeRowsPerPage } = useTable({});

  const [promptsToRender, setPromptsToRender] = useState<PromptItemResponse[]>(items);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchPrompts = async (params: GetPromptsSearchParams) => {
    !isLoading && setIsLoading(true);
    try {
      const { data } = await getAllPrompts({ page, size: rowsPerPage, ...params });

      setPrompts(data);

      if (params.page === 1 && page !== 1) setPage(1);
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get prompts, please try again!' } });
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = async (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);
    await fetchPrompts({ page: newPage });
  };

  useEffect(() => {
    fetchPrompts({});
  }, [rowsPerPage]);

  const [searchQuery, setSearchQuery] = useState('');

  const {
    handleClosePopover: closeFilterMenu,
    handleOpenPopover: openFilterMenu,
    openPopover: isFilterMenuOpen,
  } = usePopover();

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value);

  const debouncedSearchValue = useDebounce(searchQuery, 200);

  useEffect(() => {
    if (debouncedSearchValue) {
      const filtered = items.filter(prompt => prompt.name.toLowerCase().includes(debouncedSearchValue.toLowerCase()));

      setPromptsToRender(filtered);
    } else {
      setPromptsToRender(items);
    }
  }, [debouncedSearchValue, items]);

  const {
    actionState,
    isActionPending,
    setIsActionPending,
    onClickActionOptionHandler,
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
  } = useActionDialogManagement();

  const handleDeletePrompt = async () => {
    if (!actionState.id) return;
    setIsActionPending(true);
    try {
      await deletePrompt(actionState.id);
      closeDeleteDialogWindow();
      notice(ToastType.SUCCESS, 'Prompt has been successfully deleted');

      await fetchPrompts({ page: 1 });
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to delete prompt, please try again!' } });
    } finally {
      setIsActionPending(false);
    }
  };

  const [isOpenAddPromptDialogWindow, openAddPromptDialogWindow, closeAddPromptDialogWindow] = useBoolean();

  return {
    closeFilterMenu,
    openFilterMenu,
    isFilterMenuOpen,
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
    navigate,
    handleDeletePrompt,
    handleSearchChange,
    isLoading,
    searchQuery,
    onClickActionOptionHandler,
    actionState,
    isActionPending,
    promptsToRender,
    isOpenAddPromptDialogWindow,
    openAddPromptDialogWindow,
    closeAddPromptDialogWindow,
    fetchPrompts,
    page,
    rowsPerPage,
    pagination,
    onChangePage,
    onChangeRowsPerPage,
    isRoleAItester,
  };
};
