import { useEffect, useState } from 'react';

import {
  GetOfficesParams,
  GetPatientsParams,
  GetPracticesParams,
  OfficeListItem,
  Patient,
  PracticeListItem,
  getOffices,
  getPatients,
  getPractices,
} from 'apiServices';

import { backendErrorHandler } from 'utils/errorHanders';

import { useAutocompleteDebouncedSearch } from './useAutocompleteDebouncedSearch';

type UseOptionsProps = {
  isImmediateFetchPractices?: boolean;
};

type OptionsInitialState<T> = {
  isLoading: boolean;
  totalPages: number | null;
  items: T[];
};

const OPTIONS_PER_PAGE = 50;

export const useOptions = ({ isImmediateFetchPractices = true }: UseOptionsProps) => {
  const [practicesState, setPracticesState] = useState<OptionsInitialState<PracticeListItem>>({
    isLoading: true,
    totalPages: null,
    items: [],
  });

  const fetchPractices = async (params?: GetPracticesParams) => {
    !practicesState.isLoading && setPracticesState(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await getPractices({ page: 1, size: OPTIONS_PER_PAGE, is_active: true, ...params });

      setPracticesState(prev => ({
        ...prev,
        totalPages: data?.pages,
        items: params?.page > 1 ? [...prev.items, ...data?.items] : data?.items,
      }));
    } catch (error) {
      console.error(error);
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get practices, please try again!' } });
    } finally {
      setPracticesState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const {
    searchValue: practiceSearchValue,
    debouncedSearchValue: debouncedPracticeSearchValue,
    onChangeSearchValueHandler: onChangePracticeInputValueHandler,
  } = useAutocompleteDebouncedSearch();

  const {
    searchValue: patientSearchValue,
    debouncedSearchValue: debouncedPatientSearchValue,
    onChangeSearchValueHandler: onChangePatientInputValueHandler,
  } = useAutocompleteDebouncedSearch();

  useEffect(() => {
    if (isImmediateFetchPractices) {
      fetchPractices({
        ...(debouncedPracticeSearchValue && { search: debouncedPracticeSearchValue }),
      });
    }
  }, [debouncedPracticeSearchValue, isImmediateFetchPractices]);

  const [officesState, setOfficesState] = useState<OptionsInitialState<OfficeListItem>>({
    isLoading: true,
    totalPages: null,
    items: [],
  });

  const fetchOffices = async (params?: GetOfficesParams) => {
    setOfficesState(prev => ({ ...prev, isLoading: true }));

    try {
      const { data } = await getOffices(params);

      setOfficesState(prev => ({
        ...prev,
        totalPages: data?.pages ?? null,
        items: params?.page > 1 ? [...prev.items, ...data?.items] : data?.items,
      }));
    } catch (error) {
      backendErrorHandler({
        error,
        config: { customErrorMessage: 'Failed to get practice offices, please try again!' },
      });
    } finally {
      setOfficesState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const [patientsState, setPatientsState] = useState<OptionsInitialState<Patient>>({
    isLoading: false,
    totalPages: null,
    items: [],
  });

  const fetchPatients = async (params?: GetPatientsParams) => {
    setPatientsState(prev => ({ ...prev, isLoading: true }));

    try {
      const { data } = await getPatients({ page: 1, size: OPTIONS_PER_PAGE, ...params });

      setPatientsState(prev => ({
        ...prev,
        totalPages: data?.pages ?? null,
        items: params?.page > 1 ? [...prev.items, ...data?.items] : data?.items,
      }));
    } catch (error) {
      backendErrorHandler({
        error,
        config: { customErrorMessage: 'Failed to get patients, please try again!' },
      });
    } finally {
      setPatientsState(prev => ({ ...prev, isLoading: false }));
    }
  };

  return {
    practiceSearchValue,
    debouncedPracticeSearchValue,
    officesState,
    patientsState,
    practicesState,
    onChangePracticeInputValueHandler,
    fetchOffices,
    fetchPractices,
    fetchPatients,
    patientSearchValue,
    debouncedPatientSearchValue,
    onChangePatientInputValueHandler,
  };
};
