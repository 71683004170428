import { FC, useCallback, useState } from 'react';

import { Grid, Stack } from '@mui/material';

import { Icon } from 'components/Icon';
import MDButton from 'components/MDButton';
import { useBoolean } from 'hooks';

import { AddItemCard, Header, PhaseCard } from './components';
import { CardItem, Phase } from './types';

const initialPhasesStateValue: Phase[] = [];

export const ConsultationWorkflowBuilder: FC = () => {
  const [isAddPhaseMode, onClickAddPhaseButtonHandler, onClickCancelAddPhaseButtonHandler] = useBoolean();

  const [phases, setPhases] = useState<Phase[]>(initialPhasesStateValue);

  const onClickSavePhaseButtonHandler = useCallback(
    (phase: Phase) => setPhases(prevState => [...prevState, phase]),
    []
  );

  const onClickSaveStepButtonHandler = useCallback((phaseId: string, newStepData: CardItem) => {
    setPhases(prevPhases =>
      prevPhases.map(phase =>
        phase.id === phaseId
          ? {
              ...phase,
              steps: [...(phase?.steps || []), { ...newStepData, content: [] }],
            }
          : phase
      )
    );
  }, []);

  return (
    <section>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12}>
          <Stack gap={2}>
            {phases.length ? (
              <Stack gap={2}>
                {phases.map(phase => (
                  <PhaseCard
                    key={phase.id}
                    id={phase.id}
                    steps={phase.steps}
                    name={phase.name}
                    onSaveStepHandler={onClickSaveStepButtonHandler}
                  />
                ))}
              </Stack>
            ) : null}

            {isAddPhaseMode ? (
              <AddItemCard
                onCancelHandler={onClickCancelAddPhaseButtonHandler}
                onSaveHandler={onClickSavePhaseButtonHandler}
                label="Phase Name"
                placeholder="Enter Phase Name"
              />
            ) : (
              <Stack justifyContent="center" alignItems="center">
                <MDButton
                  variant="text"
                  color="dark"
                  onClick={onClickAddPhaseButtonHandler}
                  startIcon={<Icon type="add" />}
                >
                  Add Phase
                </MDButton>
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>
    </section>
  );
};
