import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';

type ButtonSize = 'small' | 'medium' | 'large';
type ButtonVariant = 'text' | 'contained' | 'outlined' | 'gradient';
type ButtonColor = Exclude<TypeColor, 'inherit' | 'text' | 'white'>;

export type ButtonOwnerState = Partial<{
  color: ButtonColor;
  variant: ButtonVariant;
  size: ButtonSize;
  circular: boolean;
  iconOnly: boolean;
}>;

export default styled(Button, { shouldForwardProp: propName => propName !== 'ownerState' })<{
  ownerState: ButtonOwnerState;
}>(({ theme, ownerState }) => {
  const { palette, functions, borders, boxShadows } = theme;
  const { color, variant, size, circular, iconOnly } = ownerState;

  const { white, text, transparent, gradients, grey } = palette;
  const { boxShadow, linearGradient, pxToRem, rgba } = functions;
  const { borderRadius } = borders;
  const { colored } = boxShadows;

  const containedStyles = () => {
    const backgroundValue = palette[color] ? palette[color].main : white.main;

    const boxShadowValue = colored[color]
      ? `${boxShadow([0, 3], [3, 0], palette[color].main, 0.15)}, ${boxShadow(
          [0, 3],
          [1, -2],
          palette[color].main,
          0.2
        )}, ${boxShadow([0, 1], [5, 0], palette[color].main, 0.15)}`
      : 'none';

    const hoveredBoxShadowValue = colored[color]
      ? `${boxShadow([0, 14], [26, -12], palette[color].main, 0.4)}, ${boxShadow(
          [0, 4],
          [23, 0],
          palette[color].main,
          0.15
        )}, ${boxShadow([0, 8], [10, -5], palette[color].main, 0.2)}`
      : 'none';

    let colorValue = white.main;

    if (color === 'light' || !palette[color]) {
      colorValue = text.main;
    }

    let focusedColorValue = white.main;

    if (color === 'primary' || color === 'error' || color === 'dark') {
      focusedColorValue = white.main;
    }

    return {
      background: backgroundValue,
      color: colorValue,
      boxShadow: boxShadowValue,

      '&:hover': {
        backgroundColor: backgroundValue,
        color: colorValue,
        boxShadow: hoveredBoxShadowValue,
      },

      '&:disabled': {
        backgroundColor: backgroundValue,
        color: focusedColorValue,
      },
    };
  };

  const outlinedStyles = () => {
    const backgroundValue = transparent.main;

    const colorValue = palette[color] ? palette[color].main : white.main;

    const borderColorValue = palette[color] ? palette[color].main : rgba(white.main, 0.75);

    return {
      background: backgroundValue,
      color: colorValue,
      border: `${pxToRem(1)} solid ${borderColorValue}`,

      '&:hover': {
        background: transparent.main,
        color: colorValue,
        borderColor: colorValue,
        opacity: 0.85,
      },

      '&:active:not(:hover)': {
        backgroundColor: colorValue,
        color: white.main,
        opacity: 0.85,
      },

      '&:disabled': {
        color: colorValue,
        borderColor: colorValue,
      },
    };
  };

  const gradientStyles = () => {
    const backgroundValue = !gradients[color]
      ? white.main
      : linearGradient(gradients[color].main, gradients[color].state);

    const boxShadowValue = colored[color]
      ? `${boxShadow([0, 3], [3, 0], palette[color].main, 0.15)}, ${boxShadow(
          [0, 3],
          [1, -2],
          palette[color].main,
          0.2
        )}, ${boxShadow([0, 1], [5, 0], palette[color].main, 0.15)}`
      : 'none';

    const hoveredBoxShadowValue = colored[color]
      ? `${boxShadow([0, 14], [26, -12], palette[color].main, 0.4)}, ${boxShadow(
          [0, 4],
          [23, 0],
          palette[color].main,
          0.15
        )}, ${boxShadow([0, 8], [10, -5], palette[color].main, 0.2)}`
      : 'none';

    let colorValue = white.main;

    if (color === 'light') {
      colorValue = gradients.dark.state;
    }

    return {
      background: backgroundValue,
      color: colorValue,
      boxShadow: boxShadowValue,

      '&:hover': {
        boxShadow: hoveredBoxShadowValue,
        color: colorValue,
      },

      '&:disabled': {
        background: backgroundValue,
        color: colorValue,
      },
    };
  };

  const textStyles = () => {
    const colorValue = palette[color] ? palette[color]?.main : white?.main;

    const focusedColorValue = palette[color] ? palette[color].focus : white?.focus;

    return {
      color: colorValue,

      '&:hover': {
        backgroundColor: alpha(colorValue, 0.04),
        color: focusedColorValue,
      },
    };
  };

  const circularStyles = () => ({
    borderRadius: borderRadius.section,
  });

  const iconOnlyStyles = () => {
    let sizeValue = pxToRem(38);

    if (size === 'small') {
      sizeValue = pxToRem(25.4);
    } else if (size === 'large') {
      sizeValue = pxToRem(52);
    }

    let paddingValue = `${pxToRem(11)} ${pxToRem(11)} ${pxToRem(10)}`;

    if (size === 'small') {
      paddingValue = pxToRem(4.5);
    } else if (size === 'large') {
      paddingValue = pxToRem(16);
    }

    return {
      width: sizeValue,
      minWidth: sizeValue,
      height: sizeValue,
      minHeight: sizeValue,
      padding: paddingValue,

      '& .material-icons': {
        marginTop: 0,
      },

      '&:hover, &:focus, &:active': {
        transform: 'none',
      },
    };
  };

  const defaultStyles = {
    lineHeight: 'initial',
    ...(size === 'large' && {
      minHeight: 36,
    }),
    ...(size === 'medium' && {
      minHeight: 32,
    }),
    ...(size === 'small' && {
      minHeight: 30,
    }),
  };

  return {
    ...defaultStyles,
    ...(variant === 'contained' && containedStyles()),
    ...(variant === 'outlined' && outlinedStyles()),
    ...(variant === 'gradient' && gradientStyles()),
    ...(variant === 'text' && textStyles()),
    ...(circular && circularStyles()),
    ...(iconOnly && iconOnlyStyles()),
  };
});
