import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';

import contained from 'assets/theme/components/button/contained';
import outlined from 'assets/theme/components/button/outlined';
import root from 'assets/theme/components/button/root';
import text from 'assets/theme/components/button/text';

const button: {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants<Theme>['MuiButton'];
} = {
  styleOverrides: {
    root,
    contained: contained.base,
    containedSizeSmall: contained.small,
    containedSizeLarge: contained.large,
    containedPrimary: contained.primary,
    containedSecondary: contained.secondary,
    outlined: outlined.base,
    outlinedSizeSmall: outlined.small,
    outlinedSizeLarge: outlined.large,
    outlinedPrimary: outlined.primary,
    outlinedSecondary: outlined.secondary,
    text: text.base,
    textSizeSmall: text.small,
    textSizeLarge: text.large,
  },
};

export default button;
