import { FC, useCallback, useState } from 'react';

import { Box, Card, CardContent, Divider, IconButton, Stack, Typography, alpha } from '@mui/material';
import { ContactPerson, deletePracticeContactPerson } from 'apiServices';
import { useFormContext } from 'react-hook-form';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { FormHeader, FormItem } from 'components/Form';
import { RHFTextField } from 'components/HookForm';
import { Icon } from 'components/Icon';
import { PopoverWrapper } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import { ToastType, notice } from 'components/ToastNotification';
import { useBoolean, usePopover, useUserProfile } from 'hooks';
import { PRACTICE_CONTACT_PERSON_ID, PRACTICE_PROFILE_ID } from 'pages/PracticeManagement/constants';
import { backendErrorHandler } from 'utils/errorHanders';

import { ContactPersonCard } from '../ContactPersonCard';
import { CreateContactPersonMenuContent } from '../CreateContactPersonMenuContent';
import { EditContactPersonMenuContent } from '../EditContactPersonMenuContent';
import { EditPracticeFormSchema } from './form.config';

type EditPracticeFormProps = {
  refs: React.RefObject<HTMLDivElement>[];
  practiceId: string;
  fetchPractice: (practiceId: string) => Promise<void>;
  contactPersons: ContactPerson[];
};

const ScrollSX = {
  sx: { py: 1, maxHeight: 450, width: 1 },
  style: { width: '100%' },
};

export const EditPracticeForm: FC<EditPracticeFormProps> = ({ contactPersons, refs, practiceId, fetchPractice }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<EditPracticeFormSchema>();

  const {
    userRoles: { isRoleAdmin, isRolePracticeAdmin },
  } = useUserProfile();

  const isViewOnly = !isRoleAdmin && !isRolePracticeAdmin;

  const [isOpenRemoveDialog, openRemoveDialog, closeRemoveDialog] = useBoolean();

  const [selectedContactPersonId, setSelectedContactPersonId] = useState('');

  const onClickRemoveButtonHandler = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    openRemoveDialog();
    setSelectedContactPersonId(event.currentTarget.id);
  }, []);

  const [isRemovingContactPerson, setIsRemovingContactPerson] = useState(false);

  const onRemoveContactPersonHandler = async () => {
    try {
      setIsRemovingContactPerson(true);

      await deletePracticeContactPerson(practiceId, selectedContactPersonId);
      await fetchPractice(practiceId);

      setIsRemovingContactPerson(false);
      closeRemoveDialog();
      setSelectedContactPersonId('');

      notice(ToastType.SUCCESS, 'Contact person has been successfully removed!');
    } catch (error) {
      setIsRemovingContactPerson(false);
      console.error(error);
      backendErrorHandler({
        error,
        config: { customErrorMessage: 'Failed to remove contact person, please try again!' },
      });
    }
  };

  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const {
    handleClosePopover: handleCloseEditPopover,
    handleOpenPopover: handleOpenEditPopover,
    openPopover: isEditPopoverOpen,
  } = usePopover();

  const onClickEditButtonHandler = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedContactPersonId(event.currentTarget.id);
    handleOpenEditPopover(event);
  }, []);

  return (
    <Stack width={1} spacing={2} mb={2}>
      <Card ref={refs?.[0]} id={PRACTICE_PROFILE_ID}>
        <CardContent>
          <FormHeader title="Practice profile" subtitle="Fill out the details below to create a new account." />

          <Stack>
            <FormItem title="Practice name">
              <RHFTextField
                register={register}
                registerName="name"
                registerErrors={errors?.name?.message}
                fullWidth
                placeholder="Practice name"
                disabled={isViewOnly}
              />
            </FormItem>

            <FormItem title="Practice Summary" isDivider={false}>
              <RHFTextField
                register={register}
                registerName="summary"
                registerErrors={errors?.summary?.message}
                fullWidth
                placeholder="Practice Summary"
                maxRows={8}
                multiline
                disabled={isViewOnly}
              />
            </FormItem>
          </Stack>
        </CardContent>
      </Card>

      <Card ref={refs?.[1]} id={PRACTICE_CONTACT_PERSON_ID}>
        <CardContent>
          <Typography variant="subtitle1" fontWeight={600}>
            Contact persons
          </Typography>

          {!isViewOnly && (
            <Stack
              spacing={0.5}
              mb={1.5}
              sx={{
                ...(openPopover && {
                  boxShadow: theme => theme.boxShadows.md,
                  bgcolor: theme => alpha(theme.palette.primary.light, 0.05),
                }),
              }}
            >
              <Stack direction={{ xs: 'column', sm: 'row' }} gap={1} justifyContent="space-between" alignItems="center">
                <Typography fontSize={14} fontWeight={400} color="text.main">
                  Add contact person
                </Typography>
                <IconButton size="small" onClick={handleOpenPopover}>
                  <Icon type="add" />
                </IconButton>
              </Stack>
              <Divider />
            </Stack>
          )}

          {contactPersons?.length ? (
            <Box component={Scrollbar} sx={ScrollSX.sx} style={ScrollSX.style}>
              <Stack spacing={1.5}>
                {contactPersons?.map(person => {
                  return (
                    <ContactPersonCard
                      key={person?.id}
                      id={person?.id}
                      firstName={person?.firstName}
                      lastName={person?.lastName}
                      phone={person?.contact?.phone}
                      email={person?.contact?.email}
                      onClickRemoveButtonHandler={onClickRemoveButtonHandler}
                      onClickEditButtonHandler={onClickEditButtonHandler}
                      isViewOnly={isViewOnly}
                    />
                  );
                })}
              </Stack>
            </Box>
          ) : (
            <Typography py={1} textAlign="center" variant="body2" color="grey.600">
              No contact persons
            </Typography>
          )}
        </CardContent>
      </Card>

      <PopoverWrapper
        open={openPopover}
        disabledArrow
        title="Add Contact Person"
        handleClosePopover={handleClosePopover}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ maxWidth: { xs: 'none', sm: 550 } }}
        variant="form"
      >
        <CreateContactPersonMenuContent
          fetchPractice={fetchPractice}
          closeMenuHandler={handleClosePopover}
          practiceId={practiceId}
        />
      </PopoverWrapper>

      <PopoverWrapper
        open={isEditPopoverOpen}
        disabledArrow
        title="Edit Contact Person"
        handleClosePopover={handleCloseEditPopover}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ maxWidth: { xs: 'none', sm: 550 } }}
        variant="form"
      >
        <EditContactPersonMenuContent
          fetchPractice={fetchPractice}
          closeMenuHandler={handleCloseEditPopover}
          practiceId={practiceId}
          contactPersonId={selectedContactPersonId}
        />
      </PopoverWrapper>

      <BaseDialogWindow
        onClickCancelButtonHandler={closeRemoveDialog}
        open={isOpenRemoveDialog}
        description="Are you sure you want to remove contact person?"
        title="Removing contact person"
        approveButtonTitle="Remove"
        onClickApproveButtonHandler={onRemoveContactPersonHandler}
        isApproveButtonDisabled={isRemovingContactPerson}
        isApproveButtonLoading={isRemovingContactPerson}
      />
    </Stack>
  );
};
