import { Navigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { useUserProfile } from 'hooks';

export const IndexPage = () => {
  const {
    userRoles: { isRoleAItester },
  } = useUserProfile();

  return <Navigate to={isRoleAItester ? ROUTES.promptLibrary : ROUTES.patients} />;
};
