import { FC } from 'react';

import { Box, Card, Dialog, Grid, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { PopoverWrapper } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import {
  CustomTableHeader,
  CustomTablePagination,
  TableFiltersPanel,
  TableNoData,
  TableSkeleton,
} from 'components/Table';

import { AddPracticeDialogWindowContent, FiltersMenuContent, PracticesTableRow } from './components';
import { PRACTICES_SERVICES_TABLE_HEAD } from './table.config';
import { usePracticesState } from './usePractices.state';

export const Practices: FC = () => {
  const {
    isLoading,
    practices,
    onChangeSearchValueHandler,
    searchValue,
    closeFiltersMenu,
    isFiltersMenuOpen,
    openFilterMenu,
    page,
    rowsPerPage,
    onChangeRowsPerPage,
    total,
    onChangePageHandler,
    appliedFilters,
    isActivatingProcess,
    closeActivateDialog,
    closeDeactivateDialog,
    isActivateDialogOpen,
    isDeactivateDialogOpen,
    activationState,
    onClickActivationOptionHandler,
    onActivatePracticeHandler,
    onDeactivatePracticeHandler,
    onApplyFilters,
    isAddPracticeDialogOpen,
    openAddPracticeDialog,
    closeAddPracticeDialog,
    onGetPracticesHandler,
  } = usePracticesState();

  return (
    <Box component="section" mb={2} height={1}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <TableFiltersPanel
              searchInputProps={{
                value: searchValue,
                placeholder: 'Search by name',
                onChange: onChangeSearchValueHandler,
              }}
              onOpenFilterMenuHandler={openFilterMenu}
              actionButtonProps={{ onClick: openAddPracticeDialog, children: 'Add practice' }}
            />
            <TableContainer>
              <Scrollbar>
                <Table size="medium" sx={{ minWidth: 800 }}>
                  <CustomTableHeader headLabel={PRACTICES_SERVICES_TABLE_HEAD} />
                  <TableBody>
                    {isLoading ? (
                      <TableSkeleton cellsAmount={5} />
                    ) : (
                      practices.map(practice => (
                        <PracticesTableRow
                          key={practice?.id}
                          onClickActivateDeactivateOptionHandler={onClickActivationOptionHandler}
                          id={practice?.id}
                          name={practice?.name}
                          createdAt={practice?.createdAt}
                          updatedAt={practice?.updatedAt}
                          is_active={practice?.is_active}
                          status={practice?.status}
                        />
                      ))
                    )}
                    <TableNoData isNotFound={!isLoading && !practices.length} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
            <CustomTablePagination
              count={total}
              page={page - 1}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePageHandler}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Card>
        </Grid>
      </Grid>

      <PopoverWrapper
        open={isFiltersMenuOpen}
        handleClosePopover={closeFiltersMenu}
        title="Filter by Practices"
        variant="filter"
      >
        <FiltersMenuContent
          appliedFilters={appliedFilters}
          setAppliedFilters={onApplyFilters}
          closeMenuHandler={closeFiltersMenu}
        />
      </PopoverWrapper>

      <BaseDialogWindow
        open={isActivateDialogOpen}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              You are about to activate{' '}
              <Typography variant="button" fontWeight={700}>
                {activationState.practiceName}
              </Typography>{' '}
              account. Please be aware that activating the account will result in the following:
            </Typography>
            <Stack>
              <Typography variant="body2" fontWeight={400}>
                - The practice will regain access to their account.
              </Typography>
              <Typography variant="body2" fontWeight={400}>
                - All users associated with this practice will be able to log in and access all Aesthetics360 services
                and features.
              </Typography>
            </Stack>
          </Stack>
        }
        title="Activate Practice Account"
        onClickCancelButtonHandler={closeActivateDialog}
        approveButtonTitle="Make active"
        onClickApproveButtonHandler={onActivatePracticeHandler}
        isApproveButtonLoading={isActivatingProcess}
        isApproveButtonDisabled={isActivatingProcess}
      />
      <BaseDialogWindow
        open={isDeactivateDialogOpen}
        description={
          <Stack spacing={1}>
            <Typography variant="body2">
              You are about to inactivate{' '}
              <Typography variant="button" fontWeight={700}>
                {activationState.practiceName}
              </Typography>{' '}
              account. Please be aware that inactivating the account will result in the following:
            </Typography>
            <Stack>
              <Typography variant="body2" fontWeight={400}>
                - The practice will no longer have access to their account.
              </Typography>
              <Typography variant="body2" fontWeight={400}>
                - All users associated with this practice will be unable to log in or access any Aesthetics360 services
                and features.
              </Typography>
            </Stack>
          </Stack>
        }
        title="Inactivate Practice Account"
        onClickCancelButtonHandler={closeDeactivateDialog}
        approveButtonTitle="Make inactive"
        onClickApproveButtonHandler={onDeactivatePracticeHandler}
        isApproveButtonLoading={isActivatingProcess}
        isApproveButtonDisabled={isActivatingProcess}
      />
      <Dialog open={isAddPracticeDialogOpen} onClose={closeAddPracticeDialog} fullWidth>
        <AddPracticeDialogWindowContent fetchItems={onGetPracticesHandler} closeMenuHandler={closeAddPracticeDialog} />
      </Dialog>
    </Box>
  );
};
