import { FC } from 'react';

import { Chip, ChipProps } from '@mui/material';

import { UserRoleLabels } from 'constants/roles';
import { USER_ROLES } from 'types/enums';

export const UserRoleColors: Partial<Record<USER_ROLES, ChipProps['color']>> = {
  [USER_ROLES.ROLE_ADMIN]: 'secondary',
  [USER_ROLES.ROLE_AI_TESTER]: 'error',
  [USER_ROLES.ROLE_PRACTICE_ADMIN]: 'success',
  [USER_ROLES.ROLE_PRACTICE_DOCTOR]: 'primary',
};

type MDUserRoleChipProps = ChipProps & {
  userRole: USER_ROLES;
};

export const MDUserRoleChip: FC<MDUserRoleChipProps> = ({ userRole, ...chipProps }) => (
  <Chip variant="soft" color={UserRoleColors[userRole] || 'primary'} label={UserRoleLabels[userRole]} {...chipProps} />
);
