import { FC, useEffect } from 'react';

import { Stack, Typography } from '@mui/material';
import { useReward } from 'react-rewards';

import { ROUTES } from 'constants/routes';
import { useRouter } from 'hooks/useRouter';

export const CompletedStep: FC = () => {
  const { navigate } = useRouter();

  const { reward } = useReward('rewardId', 'confetti', {
    elementCount: 170,
    spread: 120,
    startVelocity: 35,
    decay: 0.92,
    onAnimationComplete: () => navigate(ROUTES.signIn),
  });

  useEffect(() => {
    reward();
  }, []);

  return (
    <Stack spacing={2} textAlign="center">
      <span id="rewardId" />
      <Typography variant="h2" fontWeight={600} color="primary.light">
        You’re all set!
      </Typography>
      <Stack spacing={2.5}>
        <Typography variant="button" fontWeight={400}>
          Your password has been successfully set.
        </Typography>

        <Typography variant="button" fontWeight={400}>
          You can now log in to the Aesthetics360 application using your new credentials.
        </Typography>
      </Stack>
    </Stack>
  );
};
