import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { resetPassword } from 'aws-amplify/auth';
import { IllustrationLayout } from 'layouts/IllustrationLayout';
import { BackButton } from 'layouts/IllustrationLayout/components';
import { Resolver, useForm } from 'react-hook-form';

import { RHFTextField } from 'components/HookForm';
import MDButton from 'components/MDButton';
import { ToastType, notice } from 'components/ToastNotification';
import { ROUTES } from 'constants/routes';
import { useRouter } from 'hooks/useRouter';
import { awsCognitoErrorHandler } from 'utils/errorHanders';

import { RecoveryPasswordFormSchema, recoveryPasswordValidationFormSchema } from './form.config';

export const ForgotPassword: FC = () => {
  const router = useRouter();

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
  } = useForm<RecoveryPasswordFormSchema>({
    resolver: yupResolver(recoveryPasswordValidationFormSchema) as Resolver<RecoveryPasswordFormSchema>,
    mode: 'onTouched',
    defaultValues: { email: '' },
  });

  const onFormSubmitHandler = handleSubmit(async ({ email }) => {
    try {
      const { nextStep } = await resetPassword({ username: email });

      notice(ToastType.SUCCESS, 'Reset password code has been sent to your email, please check your inbox!');
      router.navigate(ROUTES.resetPassword, {
        state: { emailHidden: nextStep.codeDeliveryDetails.destination, email },
      });
    } catch (error) {
      console.error(error);
      awsCognitoErrorHandler({ error });
    }
  });

  return (
    <IllustrationLayout
      title="Forgot password"
      description="Check if you entered the email correctly"
      backButton={<BackButton title="Back to Login" navigateTo={ROUTES.signIn} />}
    >
      <form onSubmit={onFormSubmitHandler}>
        <RHFTextField
          register={register}
          registerName="email"
          label="Email"
          registerErrors={errors?.email?.message}
          fullWidth
        />
        <Box mt={4} mb={1}>
          <MDButton type="submit" fullWidth isLoading={isSubmitting} disabled={!isValid || isSubmitting}>
            Send verification code
          </MDButton>
        </Box>
      </form>
    </IllustrationLayout>
  );
};
