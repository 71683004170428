import { FC } from 'react';

import { AddOutlined } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

import MDButton, { MDButtonProps } from 'components/MDButton';

type ActionButtonProps = {
  title: string;
  onClickHandler?: () => void | Promise<void>;
  buttonProps?: MDButtonProps;
};

type EmptyStateWithActionProps = {
  title: string;
  subtitle?: string;
  actionButtonProps: ActionButtonProps;
};

export const EmptyStateWithAction: FC<EmptyStateWithActionProps> = ({ title, subtitle, actionButtonProps }) => {
  return (
    <Stack flex={1} alignItems="center" justifyContent="center" position="relative">
      <Stack spacing={2}>
        <Stack textAlign="center">
          <Typography variant="h4">{title}</Typography>
          <Typography variant="h6" color="grey.600">
            {subtitle}
          </Typography>
        </Stack>

        <Box sx={{ m: '16px auto !important' }}>
          <MDButton
            variant="contained"
            color="primary"
            size="medium"
            onClick={actionButtonProps?.onClickHandler}
            startIcon={<AddOutlined fontSize="medium" sx={{ width: 20, height: 20, mr: 1 }} />}
            {...actionButtonProps?.buttonProps}
          >
            {actionButtonProps.title}
          </MDButton>
        </Box>
      </Stack>
    </Stack>
  );
};
