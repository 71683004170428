import { getProfileLegalDocs } from 'apiServices/dictionary/profile.api';
import { StateCreator } from 'zustand';

import { backendErrorHandler } from 'utils/errorHanders';

import { sliceResetFns } from '../../Store';
import { UserProfileInitialState, UserProfileSliceType } from './types';

const initialState: UserProfileInitialState = {
  cognitoUser: null,
  expertProfile: null,
  legalDocuments: { documents: null, isSkippedSigning: false },
};

export const UserProfileSlice: StateCreator<
  UserProfileSliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  UserProfileSliceType
> = set => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setUserProfile: value => {
      set(state => {
        state.expertProfile = value;
      });
    },
    setCognitoProfile: value => {
      set(state => {
        state.cognitoUser = value;
      });
    },
    setLegalDocuments: value => {
      set(state => {
        state.legalDocuments = value;
      });
    },

    getProfileLegalDocsHandler: async () => {
      try {
        const { data } = await getProfileLegalDocs();
        set(state => {
          state.legalDocuments.documents = data;
        });
      } catch (error) {
        console.error(error);
        backendErrorHandler({
          error,
          config: { customErrorMessage: 'Failed to get legal documents, please try again!' },
        });
      }
    },
  };
};
