import { CreateExpertPayload } from 'apiServices';
import * as yup from 'yup';

import { Shape } from 'types/RHF-types';
import { USER_ROLES } from 'types/enums';
import { EmailValidationSchema } from 'utils/formValidation';

export type CreateUserFormSchema = CreateExpertPayload;

export const createUserValidationSchema = yup
  .object()
  .shape<Shape<CreateUserFormSchema>>({
    role: yup.string<USER_ROLES>().required().label('User role'),
    practiceId: yup.string().required().label('Practice'),
    person: yup
      .object()
      .shape<Shape<CreateExpertPayload['person']>>({
        firstName: yup.string().required().label('First name'),
        middleName: yup.string().optional().label('Middle name').nullable(),
        lastName: yup.string().required().label('Last name'),
        contact: yup
          .object()
          .shape<Shape<CreateExpertPayload['person']['contact']>>({
            email: EmailValidationSchema,
          })
          .required(),
      })
      .required(),
  })
  .required();

export const defaultValues: CreateUserFormSchema = {
  role: null,
  person: {
    firstName: '',
    middleName: '',
    lastName: '',
    contact: { email: '' },
  },
  practiceId: '',
};
