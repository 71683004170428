import { Drawer, IconButton, styled } from '@mui/material';

import { SIDE_NAVIGATION } from 'constants/layout-config';

import { createTransition } from './helpers';

type DrowerProps = { isNavMini: boolean };

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  zIndex: theme.zIndex.drawer + 1,
  position: 'fixed',
  top: 12,
  border: `solid 1px ${theme.palette.divider}`,
  ':hover': {
    backgroundColor: theme.palette.common.white,
  },
}));

export const StyledNavVertical = styled('nav', {
  shouldForwardProp: prop => prop !== 'isNavMini',
})<DrowerProps>(({ theme, isNavMini }) => ({
  height: '100vh',
  display: 'flex',
  position: 'fixed',
  left: 0,
  top: 0,
  flexShrink: 0,
  transition: createTransition(theme),
  zIndex: theme.zIndex.drawer,
  width: SIDE_NAVIGATION.W_DASHBOARD,
  ...(isNavMini && {
    width: SIDE_NAVIGATION.W_DASHBOARD_MINI,
  }),
}));

export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'isNavMini',
})<DrowerProps>(({ theme, isNavMini }) => ({
  flexShrink: 0,
  overflow: 'hidden',
  width: isNavMini ? SIDE_NAVIGATION.W_DASHBOARD_MINI : SIDE_NAVIGATION.W_DASHBOARD,
  '& .MuiPaper-root': {
    zIndex: 0,
    whiteSpace: 'nowrap',
    flexShrink: 0,
    height: '100%',
    bgcolor: 'transparent',
    overflow: 'hidden',
    transition: createTransition(theme),
    width: isNavMini ? SIDE_NAVIGATION.W_DASHBOARD_MINI : SIDE_NAVIGATION.W_DASHBOARD,
  },
}));
