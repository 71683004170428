import {
  CreateGLBAalbumPairPayload,
  GLbeforeAfterAlbumPairsSearchParams,
  MoveGLBAalbumPairPayload,
  UpdateGLBAalbumPairPayload,
} from 'apiServices';
import { BASE_PRACTICE_BA_ALBUM_PAIRS_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

import { PLbeforeAfterAlbumPair } from './types';

export const get_pl_ba_album_pairs = async (params: GLbeforeAfterAlbumPairsSearchParams) =>
  await apiClient.get<ListOfItems<PLbeforeAfterAlbumPair>>(BASE_PRACTICE_BA_ALBUM_PAIRS_API_URL, { params });

export const create_pl_ba_album_pair = async (payload: CreateGLBAalbumPairPayload) =>
  await apiClient.post<PLbeforeAfterAlbumPair>(BASE_PRACTICE_BA_ALBUM_PAIRS_API_URL, payload);

export const get_pl_ba_album_pair = async (pairId: string) =>
  await apiClient.get<PLbeforeAfterAlbumPair>(`${BASE_PRACTICE_BA_ALBUM_PAIRS_API_URL}/${pairId}`);

export const update_pl_ba_album_pair = async (pairId: string, payload: UpdateGLBAalbumPairPayload) =>
  await apiClient.patch<PLbeforeAfterAlbumPair>(`${BASE_PRACTICE_BA_ALBUM_PAIRS_API_URL}/${pairId}`, payload);

export const delete_pl_ba_album_pair = async (pairId: string) =>
  await apiClient.delete(`${BASE_PRACTICE_BA_ALBUM_PAIRS_API_URL}/${pairId}`);

export const deactivate_pl_ba_album_pair = async (pairId: string) =>
  await apiClient.delete<PLbeforeAfterAlbumPair>(`${BASE_PRACTICE_BA_ALBUM_PAIRS_API_URL}/${pairId}/deactivate`);

export const activate_pl_ba_album_pair = async (pairId: string) =>
  await apiClient.patch<PLbeforeAfterAlbumPair>(`${BASE_PRACTICE_BA_ALBUM_PAIRS_API_URL}/${pairId}/activate`);

export const move_pl_ba_album_pair = async (pairId: string, payload: MoveGLBAalbumPairPayload) =>
  await apiClient.patch<PLbeforeAfterAlbumPair>(`${BASE_PRACTICE_BA_ALBUM_PAIRS_API_URL}/${pairId}/move`, payload);

export const upload_image_pl_ba_album_pair = async (pairId: string, position: UploadImagePosition, payload: FormData) =>
  await apiClient.post<PLbeforeAfterAlbumPair>(
    `${BASE_PRACTICE_BA_ALBUM_PAIRS_API_URL}/${pairId}/image/${position}`,
    payload,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );

export const delete_image_pl_ba_album_pair = async (pairId: string, position: UploadImagePosition) =>
  await apiClient.delete(`${BASE_PRACTICE_BA_ALBUM_PAIRS_API_URL}/${pairId}/image/${position}`);
