import { FC } from 'react';

import { Stack, Typography } from '@mui/material';

import { BaseDialogWindow } from 'components/BaseDialogWindow';

import { generateActivateDeactivateDialogWindowData } from './helpers';
import { ActivateDeactivateDialogWindowProps } from './types';

export const ActivateDeactivateDialogWindow: FC<ActivateDeactivateDialogWindowProps> = ({
  actionType,
  name,
  isOpen,
  isActionPending,
  onClose,
  onApprove,
  title,
  descriptionSuffix = 'the album later.',
}) => {
  const { action, fullTitle, oppositeAction } = generateActivateDeactivateDialogWindowData({ actionType, title });

  const description = (
    <Stack spacing={1}>
      <Typography variant="body2" fontWeight={400}>
        {`Are you sure you want to ${action} `}
        <Typography variant="body2" fontWeight={700} component="span">
          {name}
        </Typography>
        <Typography variant="body2" component="span" textTransform="lowercase">
          {` ${title}`}
        </Typography>
        {'? This action is reversible. You can '}
        {oppositeAction} {` ${descriptionSuffix}`}
      </Typography>
    </Stack>
  );

  return (
    <BaseDialogWindow
      open={isOpen}
      onClickCancelButtonHandler={onClose}
      description={description}
      title={fullTitle}
      isApproveButtonDisabled={isActionPending}
      isApproveButtonLoading={isActionPending}
      approveButtonTitle={fullTitle}
      onClickApproveButtonHandler={onApprove}
    />
  );
};
