import { FC } from 'react';

import { Stack } from '@mui/material';

import { FormActions } from 'components/Form';

import { ConsultationOverview } from './ConsultationOverview';

export const GeneralTabContent: FC = () => {
  return (
    <Stack component="form">
      <Stack width={1}>
        <ConsultationOverview />
      </Stack>

      <FormActions
        isDisabled={false}
        isLoading={false}
        onFormSubmitHandler={async () => {}}
        isDirtyForm={true}
        backRoute="auto"
      />
    </Stack>
  );
};
