import { useCallback, useState } from 'react';

import { AutocompleteInputChangeReason } from '@mui/material/Autocomplete';

import { useDebounce } from './useDebounce';

export const useAutocompleteDebouncedSearch = (initialValue = '', delay = 500) => {
  const [searchValue, setSearchValue] = useState(initialValue);

  const debouncedSearchValue = useDebounce(searchValue, delay);

  const onChangeSearchValueHandler = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'input') setSearchValue(value);

      if (reason === 'clear') setSearchValue('');
    },
    []
  );

  return { searchValue, debouncedSearchValue, onChangeSearchValueHandler };
};
