import { FC } from 'react';

import { Box, Link, Stack, Typography } from '@mui/material';

const LINKS = ['Terms of Service', 'Privacy Policy'];

export const Footer: FC = () => {
  const year = new Date().getFullYear();
  return (
    <Box
      width={1}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
      sx={{ textAlign: 'center' }}
    >
      <Stack direction="row">
        {LINKS.map((link, index) => (
          <Stack key={link} direction="row" alignItems="center">
            <Box
              color="grey.600"
              fontWeight={400}
              fontSize={14}
              component={Link}
              href="#"
              sx={{
                ':hover': {
                  opacity: 0.75,
                },
              }}
            >
              {link}
            </Box>
            {index !== LINKS.length - 1 && (
              <Box
                component="span"
                sx={{ mx: 1.5, width: 5, height: 5, bgcolor: theme => theme.palette.dark.main, borderRadius: '50%' }}
              />
            )}
          </Stack>
        ))}
      </Stack>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        width={1}
        sx={{ fontSize: 14 }}
      >
        <Typography variant="caption" fontWeight={400} sx={{ color: 'grey.500' }}>
          &copy; {year} Aesthetics360, LLC. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};
