import { CreatePracticePayload } from 'apiServices';
import * as yup from 'yup';

import { Shape } from 'types/RHF-types';

export type CreatePracticeFormSchema = Omit<CreatePracticePayload, 'summary' | 'is_active'> & {
  summary: string;
};

export const createPracticeValidationSchema = yup
  .object()
  .shape<Shape<CreatePracticePayload>>({
    name: yup.string().required().label('Practice Name'),
    summary: yup.string().optional().label('Practice Summary'),
  })
  .required();

export const practiceFormDefaultValues: CreatePracticeFormSchema = {
  name: '',
  summary: '',
};
