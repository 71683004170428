import { FC, memo } from 'react';

import { MoreHorizOutlined } from '@mui/icons-material';
import { Card, CardContent, Stack, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import MDButton from 'components/MDButton';
import { useBoolean } from 'hooks';

import { Step } from '../types';
import { ContentOptionsGrid } from './ContentOptionsGrid';

type StepCardProps = Step;

export const StepCard: FC<StepCardProps> = memo(({ content, id, name }) => {
  const [isAddContentMode, onClickAddContentButtonHandler] = useBoolean();

  return (
    <Card sx={{ boxShadow: 'none', bgcolor: 'grey.100' }}>
      <CardContent
        sx={{
          p: { xs: 2, lg: 3 },
          '&:last-child': {
            paddingBottom: { xs: 2, lg: 3 },
          },
        }}
      >
        <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center" mb={2.5}>
          <Stack direction="row" gap={0.5} alignItems="center">
            <Icon type="dragAndDropIcon" />
            <Typography variant="body2" fontWeight={500} lineHeight={1}>
              {name}
            </Typography>
          </Stack>

          <MDButton
            color="dark"
            sx={{ minWidth: 'unset', maxWidth: 30, maxHeight: 32 }}
            size="small"
            variant="outlined"
          >
            <MoreHorizOutlined fontSize="medium" />
          </MDButton>
        </Stack>

        {isAddContentMode ? (
          <ContentOptionsGrid />
        ) : (
          <Stack justifyContent="center" alignItems="center">
            <MDButton
              variant="text"
              color="dark"
              onClick={onClickAddContentButtonHandler}
              startIcon={<Icon type="add" />}
            >
              Add Content
            </MDButton>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
});
