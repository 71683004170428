import { FC, memo } from 'react';

import { MoreHorizOutlined } from '@mui/icons-material';
import { Card, CardContent, Stack, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import MDButton from 'components/MDButton';
import { useBoolean } from 'hooks';

import { CardItem, Phase } from '../types';
import { AddItemCard } from './AddItemCard';
import { StepCard } from './StepCard';

type PhaseCardProps = Phase & {
  onSaveStepHandler: (id: string, step: CardItem) => void;
};

export const PhaseCard: FC<PhaseCardProps> = memo(({ name, id, steps, onSaveStepHandler }) => {
  const [isAddStepMode, onClickAddStepButtonHandler, onClickCancelAddStepButtonHandler] = useBoolean();

  const onAddStepHandler = (step: CardItem) => onSaveStepHandler(id, step);

  return (
    <Card sx={{ boxShadow: 'none' }}>
      <CardContent
        sx={{
          p: { xs: 2, lg: 3 },
          '&:last-child': {
            paddingBottom: { xs: 2, lg: 3 },
          },
        }}
      >
        <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center" mb={2.5}>
          <Stack direction="row" gap={0.5} alignItems="center">
            <Icon type="dragAndDropIcon" />
            <Typography variant="h4" fontSize={22} fontWeight={500} lineHeight={1}>
              {name}
            </Typography>
          </Stack>

          <MDButton
            color="dark"
            sx={{ minWidth: 'unset', maxWidth: 30, maxHeight: 32 }}
            size="small"
            variant="outlined"
          >
            <MoreHorizOutlined fontSize="medium" />
          </MDButton>
        </Stack>
        <Stack gap={2}>
          {steps?.map(step => (
            <StepCard key={step.id} {...step} />
          ))}

          {isAddStepMode ? (
            <AddItemCard onCancelHandler={onClickCancelAddStepButtonHandler} onSaveHandler={onAddStepHandler} />
          ) : (
            <Stack justifyContent="center" alignItems="center">
              <MDButton
                variant="text"
                color="dark"
                onClick={onClickAddStepButtonHandler}
                startIcon={<Icon type="add" />}
              >
                Add Step
              </MDButton>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
});
