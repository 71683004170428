import { FC, memo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { GLProductsSearchParams, createPLProduct } from 'apiServices';
import { Resolver, useForm } from 'react-hook-form';

import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFAutocompleteField, RHFTextField } from 'components/HookForm';
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper';
import { Scrollbar } from 'components/ScrollBar';
import { ToastType, notice } from 'components/ToastNotification';
import { useOptions } from 'hooks/useOptions';
import { useUserProfile } from 'hooks/useUserProfile';
import {
  CreateCatalogPLItemFormSchema,
  createCatalogPLItemDefaultValues,
  createCatalogPLItemValidationSchema,
} from 'pages/Catalog/config';
import { formErrorHandler } from 'utils/errorHanders';

type AddProductDialogWindowFormProps = {
  onCloseDialogHandler: VoidFunction;
  onGetProductsHandler: (params: GLProductsSearchParams) => Promise<void>;
};

export const AddProductDialogWindowForm: FC<AddProductDialogWindowFormProps> = memo(
  ({ onGetProductsHandler, onCloseDialogHandler }) => {
    const {
      userRoles: { isRoleAdmin },
      practiceId,
    } = useUserProfile();

    const {
      control,
      register,
      handleSubmit,
      setError,
      formState: { isValid, isSubmitting, errors },
    } = useForm<CreateCatalogPLItemFormSchema>({
      resolver: yupResolver(createCatalogPLItemValidationSchema) as Resolver<CreateCatalogPLItemFormSchema>,
      mode: 'onTouched',
      defaultValues: {
        ...createCatalogPLItemDefaultValues,
        ...(!isRoleAdmin && {
          practiceId: practiceId || '',
        }),
      },
    });

    const onFormSubmitHandler = handleSubmit(async ({ practiceId, title, description }) => {
      try {
        await createPLProduct({ title, practiceId, ...(description && { description }) });

        onCloseDialogHandler();
        notice(ToastType.SUCCESS, 'Product has been successfully created!');

        await onGetProductsHandler({ page: 1 });
      } catch (error) {
        formErrorHandler({ error, config: { formError: { setError } } });
      }
    });

    const isSubmitButtonDisabled = !isValid || isSubmitting;

    const { fetchPractices, debouncedPracticeSearchValue, onChangePracticeInputValueHandler, practicesState } =
      useOptions({});

    return (
      <FormDialogWindowContent
        onFormSubmitHandler={onFormSubmitHandler}
        headerProps={{ title: 'Add Product', onClickCancelButtonHandler: onCloseDialogHandler }}
        actionProps={{
          approveButtonProps: { disabled: isSubmitButtonDisabled, isLoading: isSubmitting },
        }}
      >
        <Scrollbar>
          <Stack spacing={2} width={1} maxHeight={{ xs: 350, sm: 1 }} pb={1}>
            <Stack
              gap={2}
              sx={{
                '.MuiStack-root': {
                  width: 1,
                },
              }}
            >
              <RHFTextField
                register={register}
                registerName="title"
                registerErrors={errors?.title?.message}
                fullWidth
                required
                label="Name"
                placeholder="Enter name"
              />

              <RHFTextField
                register={register}
                registerName="description"
                registerErrors={errors?.description?.message}
                fullWidth
                multiline
                maxRows={8}
                minRows={6}
                placeholder="Enter description"
                label="Description"
              />

              {isRoleAdmin && (
                <InfiniteScrollWrapper
                  searchQuery={debouncedPracticeSearchValue}
                  totalPages={practicesState.totalPages}
                  fetchItemsHandler={fetchPractices}
                >
                  {({ ref }) => (
                    <RHFAutocompleteField
                      control={control}
                      name="practiceId"
                      isRequired
                      label="Practice"
                      placeholder="Select Practice"
                      options={practicesState.items}
                      onInputChange={onChangePracticeInputValueHandler}
                      loading={practicesState.isLoading}
                      valueKey="id"
                      labelKey="name"
                      lastElementRef={ref}
                    />
                  )}
                </InfiniteScrollWrapper>
              )}
            </Stack>
          </Stack>
        </Scrollbar>
      </FormDialogWindowContent>
    );
  }
);
