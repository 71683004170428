import React, { memo } from 'react';

import { Box, Typography } from '@mui/material';
import { useInView } from 'react-intersection-observer';

import avatarFallback from 'assets/icons/components/avatar_fallback_icon.svg';
import { useImageStatus } from 'hooks';
import { getInitials } from 'utils/helpers';

import { MDAvatarSkeleton } from './MDAvatar.skeleton';
import { MDAvatarProps } from './types';

export const AVATAR_SIZES_MAP = {
  xs: { width: 24, fontSize: 10 },
  sm: { width: 32, fontSize: 12 },
  md: { width: 40, fontSize: 14 },
  lg: { width: 48, fontSize: 16 },
  xl: { width: 56, fontSize: 18 },
  '2xl': { width: 64, fontSize: 22 },
  '3xl': { width: 82, fontSize: 26 },
  '4xl': { width: 100, fontSize: 28 },
};

export const MDAvatar: React.FC<MDAvatarProps> = memo(
  ({
    isShowErrorNotification = true,
    src,
    alt,
    name,
    avatarSize = 'md',
    variant = 'circular',
    sx,
    isDisabled,
    fallbackSrc,
    ...rest
  }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
    });

    const { isImageLoading, isImageLoaded, isImageLoadingError } = useImageStatus({
      src,
      isShouldStartLoading: inView,
      isShowErrorNotification,
    });

    const size = AVATAR_SIZES_MAP[avatarSize || 'md'];

    const isShouldShowFallback = (!src || isImageLoadingError) && !isImageLoading;

    const isShowInitials = isShouldShowFallback && name;

    const isShowFallbackImage = isShouldShowFallback && !name;
    return (
      <Box
        ref={ref}
        data-inview={inView}
        sx={({ palette, borders }) => ({
          width: size.width,
          height: size.width,
          backgroundColor: palette.grey[200],
          ...(!isImageLoading && {
            border: `${borders.borderWidth[1]} solid ${palette.grey[300]}`,
          }),
          color: palette.grey[600],
          fontWeight: 500,
          fontSize: size.fontSize,
          borderRadius: '50%',
          ...(variant === 'rectangular' && {
            borderRadius: 0,
          }),
          ...(variant === 'rounded' && {
            borderRadius: borders.borderRadius.lg,
          }),
          overflow: 'hidden',
          flexShrink: 0,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          transition: 'all 0.15ms ease-in-out',
          ':hover': {
            cursor: 'initial',
          },
          ...sx,
          ...(isDisabled && {
            cursor: 'initial',
            pointerEvents: 'none',
          }),
        })}
        {...rest}
      >
        <MDAvatarSkeleton
          sx={{ display: src && isImageLoading ? 'flex' : 'none' }}
          avatarSize={avatarSize}
          variant={variant}
        />
        <Box
          component="img"
          alt={alt || 'Avatar'}
          src={src}
          sx={{
            width: 1,
            height: 1,
            objectFit: 'cover',
            loading: 'lazy',
            display: src && isImageLoaded ? 'flex' : 'none',
          }}
        />

        {isShowInitials && (
          <Typography lineHeight={1} component="span" fontWeight={700} fontSize={size.fontSize}>
            {getInitials(name)}
          </Typography>
        )}

        {isShowFallbackImage && (
          <Box
            component="img"
            src={fallbackSrc || avatarFallback}
            alt="Fallback Avatar"
            sx={{
              width: '75%',
              height: '75%',
              objectFit: 'cover',
              ...(fallbackSrc && { width: 1, height: 1 }),
            }}
          />
        )}
      </Box>
    );
  }
);
