import { FC } from 'react';

import { Box, Card, CardProps } from '@mui/material';

type CardWrapperProps = CardProps & {
  children: React.ReactNode;
};

export const CardWrapper: FC<CardWrapperProps> = ({ children, sx, ...rest }) => {
  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: 'sticky',
        top: '1%',
        ...sx,
      }}
      {...rest}
    >
      <Box component="ul" display="flex" flexDirection="column" p={1.5} m={0} sx={{ listStyle: 'none' }}>
        {children}
      </Box>
    </Card>
  );
};
