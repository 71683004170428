import { memo } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { ToastContentProps, ToastOptions, toast } from 'react-toastify';

import { Icon } from 'components/Icon';
import { Scrollbar } from 'components/ScrollBar';

import { StyledToastContainer } from './ToastNotification.styles';

import 'react-toastify/dist/ReactToastify.css';

export enum ToastType {
  'SUCCESS' = 'success',
  'INFO' = 'info',
  'ERROR' = 'error',
  'WARNING' = 'warning',
}

const Icons: Record<ToastType, React.ReactNode> = {
  warning: (
    <Icon
      type="toastWarning"
      sx={{
        '& svg': {
          color: 'warning.main',
        },
      }}
    />
  ),
  success: (
    <Icon
      type="toastSuccess"
      sx={{
        '& svg': {
          color: 'primary.main',
        },
      }}
    />
  ),
  error: (
    <Icon
      type="toastError"
      sx={{
        '& svg': {
          color: 'error.main',
        },
      }}
    />
  ),
  info: (
    <Icon
      type="toastError"
      sx={{
        '& svg': {
          color: 'info.main',
        },
      }}
    />
  ),
};

type Notice = (type: ToastType, message: string, toastProps?: Partial<ToastOptions & { title: string }>) => void;

function Message(_: ToastContentProps<unknown>, message: string, type: ToastType, title?: string) {
  const Icon = Icons[type];

  return (
    <Stack direction="row" spacing={1} alignItems="flex-start">
      <Box
        sx={{
          svg: {
            width: 16,
            height: 16,
          },
        }}
      >
        {Icon}
      </Box>
      <Stack width={1} spacing={0.5}>
        <Typography lineHeight={1} textTransform="capitalize" fontSize={14} fontWeight={600}>
          {title || type}
        </Typography>
        <Scrollbar style={{ maxHeight: 200 }}>
          <Typography fontSize={14} fontWeight={400}>
            {message}
          </Typography>
        </Scrollbar>
      </Stack>
    </Stack>
  );
}

export const notice: Notice = (type = ToastType.INFO, message, toastProps) => {
  const showToast = toast[type];
  showToast(props => Message(props, message, type, toastProps?.title), {
    ...toastProps,
  });
};

export const ToastNotification = memo(
  (): JSX.Element => (
    <StyledToastContainer
      position="top-right"
      autoClose={3500}
      hideProgressBar={false}
      newestOnTop={false}
      draggable={false}
      closeOnClick
      icon={false}
      pauseOnHover
      toastStyle={{
        position: 'relative',
      }}
      bodyStyle={{
        alignItems: 'items-center',
      }}
    />
  )
);
