import { PromptItemResponse } from 'apiServices';

export const PROMPT_LIBRARY_TABLE_HEADERS: TableHeaderConfig<PromptItemResponse>[] = [
  { id: 'promptName', label: 'Prompt Name' },
  { id: 'versions', label: 'Versions' },
  // TODO: when BE will be ready
  // { id: 'workflow', label: 'Workflow' },
  // { id: 'interaction', label: 'Interaction' },
  // { id: 'assessment', label: 'Assessment' },
  { id: 'dateEdited', label: 'Date Edited' },
  { id: 'actions' },
];

export const AI_TESTER_COLUMNS = [{ width: '55%' }, { width: '45%' }];

export const DEFAULT_COLUMNS = [{ width: '55%' }, { width: '20%' }, { width: '20%' }, { width: '5%' }];
