import { BASE_PRACTICE_OFFICE_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

import { Expert } from './expert';

type OfficeLocation = {
  id: string;
  countryIsoCode: string;
  stateIsoCode: string;
  cityId: string;
  line1: string;
  line2: string;
  zip: string;
  latitude: number;
  longitude: number;
} & Timestamps;

type WorkinDay = {
  id: string;
  weekDay: number;
  isWorking: boolean;
  openAt: string;
  closeAt: string;
  isLunch: boolean;
  lunchStartAt: string;
  lunchEndAt: string;
} & Timestamps;

export type Office = {
  id: string;
  label: string;
  location: OfficeLocation;
  contact: {
    id: string;
  } & BaseContact &
    Timestamps;
  workingDays: WorkinDay[];
  experts: Expert[];
} & Timestamps;

export type GetOfficesParams = Partial<
  PaginationParams & {
    search: string;
  }
>;

export type GetOfficesByPracticeParams = GetOfficesParams & {
  practice_id: string;
};

export type OfficeListItem = Pick<Office, 'id' | 'label' | 'location'> & Timestamps;

export type CreateOfficePayload = {
  label: string;
  practiceId: string;
};

export type UpdateOfficePayload = Pick<CreateOfficePayload, 'label'>;

export const getOffices = async (params: GetOfficesParams) =>
  await apiClient.get<ListOfItems<OfficeListItem>>(BASE_PRACTICE_OFFICE_URL, { params });

export const createOffice = async (payload: CreateOfficePayload) =>
  await apiClient.post<OfficeListItem>(BASE_PRACTICE_OFFICE_URL, payload);

export const getOfficeByPractice = async (officeId: string, params: GetOfficesByPracticeParams) =>
  await apiClient.get<OfficeListItem>(`${BASE_PRACTICE_OFFICE_URL}/${officeId}`, { params });

export const getOffice = async (officeId: string) =>
  await apiClient.get<OfficeListItem>(`${BASE_PRACTICE_OFFICE_URL}/${officeId}`);

export const updateOffice = async (officeId: string, payload: UpdateOfficePayload) =>
  await apiClient.patch<OfficeListItem>(`${BASE_PRACTICE_OFFICE_URL}/${officeId}`, payload);

export const deleteOffice = async (officeId: string) =>
  await apiClient.delete(`${BASE_PRACTICE_OFFICE_URL}/${officeId}`);
