import { FC, memo, useState } from 'react';

import { InfoOutlined } from '@mui/icons-material';
import { AutocompleteRenderOptionState, IconButton, Stack, Typography } from '@mui/material';
import { Patient, PracticeListItem } from 'apiServices';

import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper';
import { MDAutocomplete } from 'components/MDAutocomplete';
import { MDAvatar } from 'components/MDAvatar';
import MDButton from 'components/MDButton';
import { useBoolean, useOptions } from 'hooks';
import { calculateAge } from 'utils/helpers';

type OptionsContentProps = {
  getUserPromptText: (patientId: string, practiceId: string) => Promise<void>;
  executePromptHandler: ({
    patientOpensearchIndexName,
    practiceOpensearchIndexName,
  }: {
    practiceOpensearchIndexName: string | null;
    patientOpensearchIndexName: string | null;
  }) => Promise<void>;
  isLoadingPromptText: boolean;
};

export const OptionsContent: FC<OptionsContentProps> = memo(
  ({ getUserPromptText, executePromptHandler, isLoadingPromptText }) => {
    const [isPatientInfoDialogOpen, openPatientInfoDialog, closePatientInfoDialog] = useBoolean(false);

    const [isLoading, setIsLoading] = useState(false);

    const {
      fetchPractices,
      debouncedPracticeSearchValue,
      onChangePracticeInputValueHandler,
      fetchPatients,
      patientsState,
      practicesState,
      debouncedPatientSearchValue,
    } = useOptions({});

    const [selectedPractice, setSelectedPractice] = useState<PracticeListItem | null>(null);

    const handlePracticeChange = async (_: React.SyntheticEvent<Element, Event>, newValue: PracticeListItem | null) => {
      setSelectedPractice(newValue);
      if (selectedPatient) setSelectedPatient(null);

      if (newValue) await fetchPatients({ practice_id: newValue?.id });
    };

    const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);

    const handlePatientChange = async (_: React.SyntheticEvent<Element, Event>, newValue: Patient | null) => {
      setSelectedPatient(newValue);

      if (newValue) await getUserPromptText(newValue?.id, newValue?.practiceId);
    };

    const onClickInfoButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      openPatientInfoDialog();
    };

    const onTestPromptHandler = async () => {
      setIsLoading(true);
      await executePromptHandler({
        practiceOpensearchIndexName: null,
        patientOpensearchIndexName: selectedPatient?.opensearchIndexName || null,
      });
      setIsLoading(false);
    };

    const isTestPromptDisabled = !selectedPractice || !selectedPatient || isLoadingPromptText || isLoading;

    return (
      <Stack p={2} spacing={2} height={1}>
        {/* TODO */}
        {/* <BaseDialogWindow
        onClickCancelButtonHandler={closePatientInfoDialog}
        open={isPatientInfoDialogOpen}
        description={<Stack spacing={1}>descr</Stack>}
        approveButtonTitle="Select"
        cancelButtonTitle="Back"
        onClickApproveButtonHandler={() => {}}
      /> */}
        <Typography variant="h5">Choose Options</Typography>

        <Stack height={1}>
          <Stack spacing={2.5} flex={1}>
            <InfiniteScrollWrapper
              searchQuery={debouncedPracticeSearchValue}
              totalPages={practicesState.totalPages}
              fetchItemsHandler={fetchPractices}
            >
              {({ ref }) => (
                <MDAutocomplete
                  inputProps={{ label: 'Practice', placeholder: 'Select Practice' }}
                  options={practicesState.items}
                  freeSolo={false}
                  multiple={false}
                  value={selectedPractice}
                  disableClearable={false}
                  loading={practicesState.isLoading}
                  onChange={handlePracticeChange}
                  onInputChange={onChangePracticeInputValueHandler}
                  lastElementRef={ref}
                  valueKey="id"
                  labelKey="name"
                />
              )}
            </InfiniteScrollWrapper>

            <InfiniteScrollWrapper
              searchQuery={debouncedPatientSearchValue}
              totalPages={patientsState.totalPages}
              fetchItemsHandler={fetchPatients}
            >
              {({ ref }) => (
                <MDAutocomplete
                  inputProps={{
                    label: 'Patient',
                    placeholder: !selectedPractice ? 'Select Practice First' : 'Select Patient',
                  }}
                  value={selectedPatient}
                  options={patientsState.items}
                  freeSolo={false}
                  disableClearable={false}
                  multiple={false}
                  loading={patientsState.isLoading}
                  disabled={!selectedPractice}
                  getOptionLabel={option => `${option?.person?.firstName} ${option?.person?.lastName}`}
                  onChange={handlePatientChange}
                  renderOption={(props, option, ownerState) =>
                    PatientOption(props, option, ownerState, onClickInfoButtonHandler)
                  }
                  valueKey="id"
                  lastElementRef={ref}
                />
              )}
            </InfiniteScrollWrapper>
            <Stack />
          </Stack>
          <MDButton onClick={onTestPromptHandler} disabled={isTestPromptDisabled} isLoading={isLoading}>
            Start Test
          </MDButton>
        </Stack>
      </Stack>
    );
  }
);

export const PatientOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: Patient,
  ownerState: AutocompleteRenderOptionState,
  onClickInfoButtonHandler: (event: React.MouseEvent<HTMLButtonElement>) => void
) => {
  const name = `${option?.person?.firstName} ${option?.person?.lastName}`;

  const description = calculateAge(option?.birthDate);
  return (
    <Stack
      {...props}
      sx={{ px: '0 !important', pr: '1px !important', '& .MuiAutocomplete-option': { px: 0 } }}
      component="li"
      direction="row"
      justifyContent="space-between !important"
    >
      <Stack direction="row" gap={0.5} alignItems="center">
        <MDAvatar avatarSize="sm" src={option?.avatarUrl} alt="Patient avatar" />
        <Stack>
          <Typography variant="button" fontSize={14} sx={{ fontWeight: 500 }}>
            {name}
          </Typography>

          <Typography variant="caption" noWrap maxWidth={150}>
            {description}
          </Typography>
        </Stack>
      </Stack>

      <IconButton onClick={onClickInfoButtonHandler} disableRipple color="default" size="small">
        <InfoOutlined />
      </IconButton>
    </Stack>
  );
};
