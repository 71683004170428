import { forwardRef, useState } from 'react';

import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  OutlinedTextFieldProps,
  Stack,
  StandardTextFieldProps,
  Typography,
} from '@mui/material';

import MDInput, { TextFieldOwnerState } from './MDInputRoot';

type MDInputPasswordProps = Omit<OutlinedTextFieldProps | StandardTextFieldProps, 'variant'> & TextFieldOwnerState;

export const MDInputPassword = forwardRef<HTMLInputElement, MDInputPasswordProps>(
  ({ label, disabled, InputProps, ...rest }, ref) => {
    const [isShowPassword, setIsShowPassword] = useState(false);

    const onClickHidePasswordIconHandler = () => setIsShowPassword(!isShowPassword);
    return (
      <Stack spacing={0.5} component="label">
        <Typography textAlign="left" variant="caption" fontWeight={400}>
          {label}
        </Typography>
        <MDInput
          ownerState={{ disabled }}
          {...rest}
          ref={ref}
          type={isShowPassword ? 'text' : 'password'}
          fullWidth={true}
          InputProps={{
            ...InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" onClick={onClickHidePasswordIconHandler} edge="end">
                  {isShowPassword ? (
                    <VisibilityOutlined fontSize="medium" />
                  ) : (
                    <VisibilityOffOutlined fontSize="medium" />
                  )}
                </IconButton>
                {InputProps?.endAdornment}
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    );
  }
);
