import { FC } from 'react';

import { Table, TableBody, TableContainer } from '@mui/material';

import { getActivateMenuItem } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import { CustomTableHeader, CustomTablePagination, TableSkeleton } from 'components/Table';
import { useBoolean } from 'hooks';

import { CONCERNS_TABLE_HEADERS } from '../../PatientView/TabContentPages/Concerns/constants';
import { RelatedConcernsTableRow } from './components';
import {
  EditRelatedConcernSideMenuContent,
  RelatedConcernSideMenu,
  ViewRelatedConcernSideMenuContent,
} from './components/SideMenu';

export const RelatedConcernsTabContent: FC = () => {
  const isLoading = false;

  const [isDialogOpen, openDialog, closeDialog] = useBoolean();

  const [isEditMenuOpen, openEditMenu, closeEditMenu] = useBoolean();

  return (
    <>
      <TableContainer>
        <Scrollbar>
          <Table size="medium" sx={{ minWidth: 600 }}>
            <CustomTableHeader headLabel={CONCERNS_TABLE_HEADERS} />

            <TableBody>
              {isLoading ? (
                <TableSkeleton cellsAmount={3} />
              ) : (
                <RelatedConcernsTableRow id="123" onClickTableRow={openDialog} onClickEditMenuItem={openEditMenu} />
              )}
              {/* TODO */}
              {/* <TableNoData isNotFound={!isLoading} /> */}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
      <CustomTablePagination
        count={0}
        page={1 - 1}
        rowsPerPage={20}
        onPageChange={(event, page) => {}}
        onRowsPerPageChange={event => {}}
      />

      <RelatedConcernSideMenu
        menuItems={[getActivateMenuItem(true, () => {})]}
        open={isDialogOpen}
        onClose={closeDialog}
        onClickCloseButton={closeDialog}
      >
        <ViewRelatedConcernSideMenuContent />
      </RelatedConcernSideMenu>

      <RelatedConcernSideMenu
        menuItems={[getActivateMenuItem(false, () => {})]}
        open={isEditMenuOpen}
        onClose={closeEditMenu}
        onClickCloseButton={closeEditMenu}
      >
        <EditRelatedConcernSideMenuContent />
      </RelatedConcernSideMenu>
    </>
  );
};
