import { FC, memo } from 'react';

import { Box, CardContent, Divider, Grid, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { FormHeader } from 'components/Form';
import { RHFAutocompleteField, RHFDatePicker, RHFTextField } from 'components/HookForm';

import { DEMOGRAPHICS_ID } from '../../constants';
import { EditPatientFormSchema } from './form.config';
import { PatientDemographicsCardProps } from './types';

export const PatientDemographicsCard: FC<PatientDemographicsCardProps> = memo(
  ({ personTitles, ethnicGroups, genders, cardRef, isAllPatientEnumsLoading }) => {
    const {
      register,
      formState: { errors },
      control,
    } = useFormContext<EditPatientFormSchema>();

    return (
      <>
        <CardContent ref={cardRef} id={DEMOGRAPHICS_ID} sx={{ p: { xs: 2, sm: 2.5, md: 3, lg: 3.5 } }}>
          <Stack>
            <FormHeader isDivider={false} title="Demographics" />

            <Box mt={1.5}>
              <Grid spacing={2} container>
                <Grid item xs={12} lg={4}>
                  <RHFTextField
                    label="First name"
                    required
                    register={register}
                    registerName="person.firstName"
                    registerErrors={errors.person?.firstName?.message}
                    fullWidth
                    placeholder="Enter First Name"
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <RHFTextField
                    label="Middle name"
                    register={register}
                    registerName="person.middleName"
                    registerErrors={errors.person?.middleName?.message}
                    fullWidth
                    placeholder="Enter Middle Name"
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <RHFTextField
                    label="Last name"
                    required
                    register={register}
                    registerName="person.lastName"
                    registerErrors={errors.person?.lastName?.message}
                    fullWidth
                    placeholder="Enter Last Name"
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <RHFDatePicker
                    label="Date of Birth"
                    control={control}
                    name="birthDate"
                    isDisableFuture
                    placeholder="Select Date of Birth"
                    sx={{
                      '.MuiTypography-root': {
                        textTransform: 'none',
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <RHFTextField
                    label="Social Security number"
                    register={register}
                    registerName="ssn"
                    registerErrors={errors?.ssn?.message}
                    fullWidth
                    type="number"
                    placeholder="Enter Social Security Number"
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <RHFAutocompleteField
                    label="Title"
                    control={control}
                    name="person.title"
                    placeholder="Select Title"
                    options={personTitles}
                    loading={isAllPatientEnumsLoading}
                    valueKey="value"
                    labelKey="value"
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <RHFAutocompleteField
                    label="Gender"
                    control={control}
                    name="gender"
                    placeholder="Select Gender"
                    options={genders}
                    loading={isAllPatientEnumsLoading}
                    valueKey="value"
                    labelKey="value"
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <RHFAutocompleteField
                    label="Ethnicity"
                    control={control}
                    name="ethnicGroup"
                    placeholder="Select Ethnicity"
                    options={ethnicGroups}
                    loading={isAllPatientEnumsLoading}
                    valueKey="value"
                    labelKey="value"
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <RHFTextField
                    label="Occupation"
                    register={register}
                    registerName="person.position"
                    registerErrors={errors.person?.position?.message}
                    fullWidth
                    placeholder="Enter Occupation"
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </CardContent>
        <Divider />
      </>
    );
  }
);
