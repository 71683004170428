import { BASE_PROMPTS_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

import {
  CreatePromptPayload,
  GetPromptsSearchParams,
  PaginatedResponse,
  PromptItemResponse,
  PromptPatchRequest,
  PromptText,
  PromptWithFeedbacksResponse,
  RunPromptResponse,
} from './types';

export const runPrompt = async (promptId: string, practice_index_name: string, patient_index_name: string) =>
  await apiClient.post<RunPromptResponse>(`/prompt-runner/${promptId}`, {
    practice_index_name: 'practice-1',
    patient_index_name: 'patient-1',
  });

export const getAllPrompts = async (params: GetPromptsSearchParams) =>
  await apiClient.get<PaginatedResponse<PromptItemResponse>>(BASE_PROMPTS_API_URL, {
    params,
  });

export const getPromptText = async (promptId: string, patientId: string, practiceId: string) =>
  apiClient.get<PromptText>(`${BASE_PROMPTS_API_URL}/${promptId}?patient_id=${patientId}&practice_id=${practiceId}`);

export const getPromptById = async (promptId: string) =>
  await apiClient.get<PromptItemResponse>(`${BASE_PROMPTS_API_URL}/${promptId}`);

export const getPromptWithFeedbacks = async (promptId: string) =>
  await apiClient.get<PromptWithFeedbacksResponse>(`/prompt-feedback/${promptId}`);

export const createPrompt = async (payload: CreatePromptPayload) =>
  await apiClient.post<PromptItemResponse>(BASE_PROMPTS_API_URL, payload);

export const updatePrompt = async (promptId: string, payload: PromptPatchRequest) =>
  await apiClient.patch<PromptItemResponse>(`${BASE_PROMPTS_API_URL}/${promptId}`, payload);

export const deletePrompt = async (promptId: string) => await apiClient.delete(`${BASE_PROMPTS_API_URL}/${promptId}`);
