import { FC, memo } from 'react';

import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFTextField } from 'components/HookForm';
import { ToastType, notice } from 'components/ToastNotification';

import { CreateConsultationFormSchema, consultationFormDefaultValues } from './form.config';

type AddConsultationDialogWindowFormProps = {
  onCloseDialogHandler: VoidFunction;
};

export const AddConsultationDialogWindowForm: FC<AddConsultationDialogWindowFormProps> = memo(
  ({ onCloseDialogHandler }) => {
    const {
      handleSubmit,
      control,
      formState: { isValid, isSubmitting, errors },
    } = useForm<CreateConsultationFormSchema>({
      mode: 'onTouched',
      defaultValues: {
        ...consultationFormDefaultValues,
      },
    });

    const onFormSubmitHandler = handleSubmit(async formData => {
      notice(ToastType.INFO, 'Feature not implemented');
      onCloseDialogHandler();
    });

    const isSubmitButtonDisabled = !isValid || isSubmitting;

    return (
      <FormDialogWindowContent
        onFormSubmitHandler={onFormSubmitHandler}
        headerProps={{
          title: 'Start Consultations',
          onClickCancelButtonHandler: onCloseDialogHandler,
        }}
        actionProps={{
          approveButtonProps: { disabled: isSubmitButtonDisabled, isLoading: isSubmitting, children: 'Submit' },
        }}
      >
        <Stack spacing={2} height={1}>
          <RHFTextField
            register={control.register}
            registerName="tempInput"
            registerErrors={errors?.tempInput?.message}
            fullWidth
            label="Temp Input"
            placeholder="Temp Input"
          />
        </Stack>
      </FormDialogWindowContent>
    );
  }
);
