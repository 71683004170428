import { FC, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import { addPatientAllergy } from 'apiServices';
import { Resolver, useForm } from 'react-hook-form';

import { RHFAutocompleteField } from 'components/HookForm';
import MDButton from 'components/MDButton';
import { ToastType, notice } from 'components/ToastNotification';
import { useDictionary } from 'hooks/useDictionary';
import { formErrorHandler } from 'utils/errorHanders';

import { CreateAllergyFormSchema, allergyDefaultValues, createAllergyValidationSchema } from './form.config';

type CreateAllergyMenuContentContentProps = {
  closeMenuHandler: VoidFunction;
  patientId: string;
  fetchPatient: (patientId: string) => Promise<void>;
};

export const CreateAllergyMenuContent: FC<CreateAllergyMenuContentContentProps> = ({
  closeMenuHandler,
  fetchPatient,
  patientId,
}) => {
  const { fetchAllergies, allergies, isAllergiesLoading } = useDictionary();

  useEffect(() => {
    fetchAllergies({ page: 1, size: 100 });
  }, []);

  const {
    handleSubmit,
    setError,
    control,
    formState: { isValid, isSubmitting },
  } = useForm<CreateAllergyFormSchema>({
    resolver: yupResolver(createAllergyValidationSchema) as Resolver<CreateAllergyFormSchema>,
    mode: 'onTouched',
    defaultValues: allergyDefaultValues,
  });

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      await addPatientAllergy(patientId, formData);
      await fetchPatient(patientId);

      closeMenuHandler();

      notice(ToastType.SUCCESS, 'Allergy has been successfully added!');
    } catch (error) {
      console.error(error);
      formErrorHandler({
        error,
        config: { formError: { setError } },
        customErrorMessage: 'Failed to add allergy, please try again!',
      });
    }
  });

  return (
    <Box
      component="form"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        onFormSubmitHandler(e);
      }}
      sx={{
        mt: 3,
        pb: 1.5,
        height: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack flex={1} spacing={2}>
        <RHFAutocompleteField
          control={control}
          name="allergyIcd10Code"
          placeholder="Select allergy"
          label="Allergy"
          options={allergies}
          loading={isAllergiesLoading}
          valueKey="icd10Code"
          labelKey="name"
        />
      </Stack>

      <Stack direction="row" width={1} justifyContent="space-between" spacing={1.5}>
        <MDButton variant="outlined" size="small" onClick={closeMenuHandler} fullWidth>
          Cancel
        </MDButton>
        <MDButton disabled={isSubmitting || !isValid} size="small" isLoading={isSubmitting} fullWidth type="submit">
          Save
        </MDButton>
      </Stack>
    </Box>
  );
};
