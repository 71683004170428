import { UpdateExpertPayload } from 'apiServices';
import * as yup from 'yup';

import { Shape } from 'types/RHF-types';
import { USER_ROLES } from 'types/enums';
import { EmailValidationSchema, PhoneValidationSchema, WebsiteLazyValidationSchema } from 'utils/formValidation';

export type EditUserFormSchema = UpdateExpertPayload;

export const editUserValidationSchema = yup
  .object()
  .shape<Shape<EditUserFormSchema>>({
    role: yup.string<USER_ROLES>().required().label('User role'),
    person: yup
      .object()
      .shape<Shape<UpdateExpertPayload['person']>>({
        firstName: yup.string().required().label('First name'),
        middleName: yup.string().optional().label('Middle name').nullable(),
        lastName: yup.string().required().label('Last name'),
        position: yup.string().optional().label('Position').nullable(),
        title: yup.string().optional().label('Title').nullable(),
        contact: yup
          .object()
          .shape<Shape<UpdateExpertPayload['person']['contact']>>({
            phone: PhoneValidationSchema,
            email: EmailValidationSchema,
            website: WebsiteLazyValidationSchema,
          })
          .required(),
      })
      .required(),
  })
  .required();
