import { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteValue } from '@mui/material/Autocomplete';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { MDAutocomplete } from 'components/MDAutocomplete';
import { MDAutocompleteProps } from 'components/MDAutocomplete/types';
import { genericMemo, getElementById } from 'utils/helpers';

export type RHFAutocompleteFieldProps<
  TField extends FieldValues,
  OptionType,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<MDAutocompleteProps<OptionType, Multiple, DisableClearable, FreeSolo>, 'inputProps'> & {
  control: Control<TField>;
  name: Path<TField>;
  valueKey: keyof OptionType;
  placeholder?: string;
  label?: string;
  onChangeHandler?: (
    option: AutocompleteValue<OptionType, Multiple, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<OptionType>
  ) => void | Promise<void>;
  isRequired?: boolean;
};

export const RHFAutocompleteField = genericMemo(
  <
    TField extends FieldValues,
    OptionType,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
  >(
    props: RHFAutocompleteFieldProps<TField, OptionType, Multiple, DisableClearable, FreeSolo>
  ) => {
    const {
      onChangeHandler,
      onInputChange,
      placeholder,
      name,
      control,
      isRequired,
      freeSolo = false as FreeSolo,
      options = [],
      multiple = false as Multiple,
      loading,
      disabled,
      label,
      sx,
      labelKey,
      valueKey,
      lastElementRef,
      getOptionLabel,
      ...others
    } = props;

    const emptyValue = (multiple ? [] : null) as AutocompleteValue<OptionType, Multiple, DisableClearable, FreeSolo>;

    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          const { onChange, value, ref, onBlur } = field;

          const currentValue = (
            Array.isArray(value) ? value : getElementById(value, valueKey, options)
          ) as AutocompleteValue<OptionType, Multiple, DisableClearable, FreeSolo>;

          const onChangeAutocompleteHandler = (
            _: React.SyntheticEvent<Element, Event>,
            newValue: AutocompleteValue<OptionType, Multiple, DisableClearable, FreeSolo>,
            reason: AutocompleteChangeReason,
            details: AutocompleteChangeDetails<OptionType>
          ) => {
            onChangeHandler?.(newValue, reason, details);

            if (Array.isArray(newValue) || typeof newValue === 'string') return onChange?.(newValue);

            if (!newValue) return onChange(null);

            onChange(newValue?.[valueKey as keyof typeof newValue]);
          };
          return (
            <MDAutocomplete
              multiple={multiple}
              freeSolo={freeSolo}
              value={currentValue ?? emptyValue}
              onChange={onChangeAutocompleteHandler}
              onInputChange={onInputChange}
              disabled={disabled}
              loading={loading}
              options={options}
              sx={{ width: 1, ...sx }}
              inputProps={{
                label,
                placeholder,
                isLoading: loading,
                required: isRequired,
                error: !!error?.message,
                helperText: error?.message,
                inputRef: ref,
                onBlur,
              }}
              lastElementRef={lastElementRef}
              valueKey={valueKey}
              {...(labelKey ? { labelKey } : { getOptionLabel })}
              {...others}
            />
          );
        }}
      />
    );
  }
);
