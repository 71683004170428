import { FC, memo } from 'react';

import { TableCell, TableRow, Typography } from '@mui/material';
import { Patient } from 'apiServices';
import { useNavigate } from 'react-router-dom';

import { MDAvatar } from 'components/MDAvatar';
import { DATE_OF_BIRTH_DATE_FORMAT } from 'constants/dateFormats';
import { calculateAge, dateToCustomFormat } from 'utils/helpers';

type PatientsTableRowProps = Pick<Patient, 'birthDate' | 'id' | 'ethnicGroup' | 'gender' | 'avatarUrl'> &
  Pick<Patient['person'], 'firstName' | 'lastName' | 'middleName'>;

export const PatientsTableRow: FC<PatientsTableRowProps> = memo(
  ({ birthDate, firstName, id, lastName, middleName, ethnicGroup, gender, avatarUrl }) => {
    const navigate = useNavigate();

    const onClickRowHandler = () => navigate(`/patients/${id}`);

    const age = calculateAge(birthDate);

    const formattedBirthData = dateToCustomFormat(birthDate, DATE_OF_BIRTH_DATE_FORMAT);

    return (
      <TableRow onClick={onClickRowHandler}>
        <TableCell>
          <MDAvatar
            isShowErrorNotification={false}
            isDisabled
            avatarSize="sm"
            src={avatarUrl}
            alt="patient avatar"
            name={`${lastName} ${firstName}`}
          />
        </TableCell>
        <TableCell>
          <Typography variant="body2">{lastName}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2">{firstName}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2">{middleName || '-'}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2">{`${formattedBirthData} (${age})`}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2">{gender}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2">{ethnicGroup}</Typography>
        </TableCell>
      </TableRow>
    );
  }
);
