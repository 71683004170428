import { FC, useState } from 'react';

import { Card, Grid, Stack, Typography, alpha } from '@mui/material';

import { Icon } from 'components/Icon';

const ITEMS = [
  {
    label: 'Image',
    icon: (
      <Icon
        type="galleryIcon"
        sx={{
          svg: {
            width: 26,
            height: 26,
          },
        }}
      />
    ),
  },
  {
    label: 'Video',
    icon: <Icon type="playVideoIcon" />,
  },
  { label: 'Text', icon: <Icon type="textIcon" /> },
  { label: 'Page', icon: <Icon type="documentsIcon" /> },
  { label: 'Folder', icon: <Icon type="folderIcon" /> },
  {
    label: 'Before & After',
    icon: <Icon type="beforeAfterIcon" />,
  },
  { label: 'Prompt', icon: <Icon type="aiIcon" /> },
  { label: 'Questionnaire', icon: <Icon type="questionnaireIcon" /> },
  { label: 'Link', icon: <Icon type="linkIcon" /> },
];

type ContentOptionsGridProps = {
  onClickCardHandler?: (label: string) => void;
};

export const ContentOptionsGrid: FC<ContentOptionsGridProps> = ({ onClickCardHandler }) => {
  const [selected, setSelected] = useState(null);

  const handleSelect = (label: string) => {
    setSelected(label);
    onClickCardHandler?.(label);
  };

  return (
    <Grid container spacing="6px" maxWidth={{ xs: 1, md: 655 }}>
      {ITEMS.map(({ label, icon }) => (
        <Grid item xs={12} sm={6} md="auto" key={label}>
          <Card
            onClick={() => handleSelect(label)}
            sx={({ palette }) => {
              const isSelected = selected === label;
              return {
                width: { xs: 1, md: 125 },
                height: 70,
                textAlign: 'center',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 1.5,
                color: isSelected ? 'primary.main' : 'grey.600',
                boxShadow: 'none',
                borderColor: isSelected ? 'primary.main' : 'transparent',
                ...(label !== selected && {
                  ':hover': {
                    borderColor: alpha(palette.primary.main, 0.25),
                    color: 'primary.main',
                    svg: { color: 'primary.main' },
                  },
                }),
                svg: { color: isSelected ? 'primary.main' : 'inherit' },
              };
            }}
          >
            <Stack alignItems="center" gap={0.5}>
              {icon}
              <Typography variant="body2" fontSize={14} fontWeight={400}>
                {label}
              </Typography>
            </Stack>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
