import { FC } from 'react';

import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { Practice } from 'apiServices';

import { MDChip } from 'components/MDChip';

type PracticeHeaderProps = Pick<Practice, 'name' | 'is_active'>;

export const PracticeHeader: FC<PracticeHeaderProps> = ({ name, is_active }) => {
  return (
    <Card>
      <CardContent sx={{ p: 2 }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" spacing={1}>
          <Stack
            justifyContent={{ xs: 'center', sm: 'flex-start' }}
            textAlign={{ xs: 'center', sm: 'left' }}
            spacing={{ xs: 1, sm: 0 }}
          >
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <Typography fontWeight={700}>{name}</Typography>

              <Box>
                <MDChip
                  size="small"
                  variant="soft"
                  color={is_active ? 'primary' : 'default'}
                  label={is_active ? 'Active' : 'Deactivated'}
                />
              </Box>
            </Stack>

            {/* TODO */}
            {/* <Typography
              variant="subtitle2"
              sx={{ color: theme => theme.palette.grey[600], fontWeight: 400, maxWidth: { xs: 400, md: 470 } }}
            >
              Wilton Manors, Florida • +1 (754) 223-4657 • info@profileaw.com
            </Typography> */}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
