import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { GetPracticesParams, createPractice } from 'apiServices';
import { Resolver, useForm } from 'react-hook-form';

import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFTextField } from 'components/HookForm';
import { ToastType, notice } from 'components/ToastNotification';
import { formErrorHandler } from 'utils/errorHanders';

import { CreatePracticeFormSchema, createPracticeValidationSchema, practiceFormDefaultValues } from './form.config';

type AddPracticeDialogWindowContentProps = {
  closeMenuHandler: VoidFunction;
  fetchItems: ({ params, isDefaultPage }: { params?: GetPracticesParams; isDefaultPage?: boolean }) => Promise<void>;
};

export const AddPracticeDialogWindowContent: FC<AddPracticeDialogWindowContentProps> = ({
  closeMenuHandler,
  fetchItems,
}) => {
  const formMethods = useForm<CreatePracticeFormSchema>({
    resolver: yupResolver(createPracticeValidationSchema) as Resolver<CreatePracticeFormSchema>,
    mode: 'onTouched',
    defaultValues: practiceFormDefaultValues,
  });

  const {
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting, errors },
    register,
  } = formMethods;

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      await createPractice({
        // ...formData,
        name: formData.name,
        is_active: true,
        // TODO: Uncomment when API is ready
        // summary: formData.summary ? { data: formData.summary } : {},
      });

      notice(ToastType.SUCCESS, 'Practice has been successfully created!');
      closeMenuHandler();

      await fetchItems({ isDefaultPage: true, params: { page: 1 } });
    } catch (error) {
      console.error(error);
      formErrorHandler({
        error,
        config: { formError: { setError } },
        customErrorMessage: 'Failed to create practice profile, please try again!',
      });
    }
  });

  const isSubmitButtonDisabled = !isValid || isSubmitting;

  return (
    <FormDialogWindowContent
      onFormSubmitHandler={onFormSubmitHandler}
      headerProps={{ title: 'Add Practice', onClickCancelButtonHandler: closeMenuHandler }}
      actionProps={{
        approveButtonProps: { disabled: isSubmitButtonDisabled, isLoading: isSubmitting },
      }}
    >
      <Stack spacing={2}>
        <RHFTextField
          register={register}
          registerName="name"
          registerErrors={errors?.name?.message}
          fullWidth
          label="Practice Name"
          required
          placeholder="Enter Practice Name"
        />

        <RHFTextField
          register={register}
          registerName="summary"
          registerErrors={errors?.summary?.message}
          fullWidth
          label="Practice Summary"
          placeholder="Enter Practice Summary"
          maxRows={8}
          multiline
        />
      </Stack>
    </FormDialogWindowContent>
  );
};
