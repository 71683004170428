import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Expert, deleteExpertAvatar, getExpert, updateExpert, uploadExpertAvatar } from 'apiServices';
import { Resolver, useForm } from 'react-hook-form';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { ToastType, notice } from 'components/ToastNotification';
import { useActionDialogManagement, useRouter, useUserProfile } from 'hooks';
import { backendErrorHandler, formErrorHandler } from 'utils/errorHanders';

import { EditUserFormSchema, editUserValidationSchema } from './form.config';

export const useUserProfileState = () => {
  const { params } = useRouter();

  const userId = params?.id;

  const { activateExpert, deactivateExpert, user, setUser, expertProfileId } = useAppStore(
    store => ({
      user: store.expert,
      setUser: store.setExpert,
      expertProfileId: store.expertProfile?.id,
      activateExpert: store.activateExpert,
      deactivateExpert: store.deactivateExpert,
    }),
    shallow
  );

  const isHideRoleField = userId === expertProfileId;

  const [isLoading, setIsLoading] = useState(true);

  const onGetUserHandler = async () => {
    !isLoading && setIsLoading(true);
    await fetchUser();
    setIsLoading(false);
  };

  const fetchUser = async (): Promise<Expert | null> => {
    try {
      const { data } = await getExpert(userId, { includePhoto: true });
      setUser(data);
      return data;
    } catch (error) {
      console.error(error);
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get user, please try again!' } });
    }
  };

  useEffect(() => {
    onGetUserHandler();

    return () => {
      setUser(null);
    };
  }, []);

  const formMethods = useForm<EditUserFormSchema>({
    resolver: yupResolver(editUserValidationSchema) as Resolver<EditUserFormSchema>,
    mode: 'onTouched',
    values: {
      person: user?.person,
      // TODO: refactor to new BE
      role: user?.role?.code,
    },
  });

  const {
    handleSubmit,
    setError,
    formState: { isDirty, isValid, isSubmitting },
  } = formMethods;

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      await updateExpert(userId, {
        role: formData.role,
        person: formData.person,
      });

      notice(ToastType.SUCCESS, 'Practice profile has been successfully edited!');
      await fetchUser();
    } catch (error) {
      console.error(error);
      formErrorHandler({
        error,
        config: { formError: { setError } },
        customErrorMessage: 'Failed to edit practice profile, please try again!',
      });
    }
  });

  const isSubmitButtonDisabled = !isDirty || !isValid || isSubmitting;

  const isNoUser = !isLoading && !user;

  const name = `${user?.person?.firstName} ${user?.person?.middleName || ''} ${user?.person?.lastName}`;

  const {
    closeActivateDialog,
    closeDeactivateDialog,
    isActivateDialogOpen,
    isActionPending,
    isDeactivateDialogOpen,
    openActivateDialog,
    openDeactivateDialog,
    setIsActionPending,
  } = useActionDialogManagement();

  const onDeactivateUserHandler = async () => {
    setIsActionPending(true);

    const resp = await deactivateExpert(userId);

    if (resp) {
      await fetchUser();
      closeDeactivateDialog();
    }
    setIsActionPending(false);
  };

  const onActivateUserHandler = async () => {
    setIsActionPending(true);

    const resp = await activateExpert(userId);

    if (resp) {
      await fetchUser();
      closeActivateDialog();
    }
    setIsActionPending(false);
  };

  const {
    userRoles: { isRoleAdmin, isRolePracticeAdmin },
    expertProfile,
    setUserProfile,
  } = useUserProfile();

  const isOwnerProfile = expertProfile?.id === userId;
  const isCanEditAvatar = isRoleAdmin || isRolePracticeAdmin || isOwnerProfile;
  const [croppedImageSrc, setCroppedImageSrc] = useState<string | null>(null);

  const onUploadAvatarHandler = async (formData: FormData, croppedImageSrc: string) => {
    setCroppedImageSrc(croppedImageSrc);
    await uploadExpertAvatar(params?.id, formData);

    const resp = await fetchUser();
    if (isOwnerProfile && resp) setUserProfile(resp);
  };

  const onDeleteAvatarHandler = async () => {
    await deleteExpertAvatar(params?.id);

    croppedImageSrc && setCroppedImageSrc(null);
    const resp = await fetchUser();
    if (isOwnerProfile && resp) setUserProfile(resp);
  };

  const avatarSrc = croppedImageSrc || user?.avatarUrl || '';

  return {
    user,
    formMethods,
    isSubmitting,
    isSubmitButtonDisabled,
    onFormSubmitHandler,
    userId,
    isLoading,
    onGetUserHandler,
    isNoUser,
    isHideRoleField,
    isDirty,
    onActivateUserHandler,
    onDeactivateUserHandler,
    isActivateDialogOpen,
    isActionPending,
    isDeactivateDialogOpen,
    openActivateDialog,
    openDeactivateDialog,
    closeActivateDialog,
    closeDeactivateDialog,
    name,
    onUploadAvatarHandler,
    onDeleteAvatarHandler,
    avatarSrc,
    isCanEditAvatar,
  };
};
