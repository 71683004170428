import { PatientSortFieldName } from 'apiServices';

export const PATIENTS_TABLE_HEAD: TableHeaderConfig<PatientSortFieldName>[] = [
  { id: 'avatar', disableSorting: true },
  { id: 'person.last_name', label: 'Last Name' },
  { id: 'person.first_name', label: 'First Name' },
  { id: 'middleName', label: 'Middle Name', disableSorting: true },
  { id: 'birthDate', label: 'Date of Birth (Age)', disableSorting: true },
  { id: 'gender', label: 'Gender', disableSorting: true },
  { id: 'ethnicGroup', label: 'Ethnicity', disableSorting: true },
];
