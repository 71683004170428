import { useCallback } from 'react';

import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { USER_ROLES } from 'types/enums';

export const useUserProfile = () => {
  const { setCognitoProfile, cognitoUser, expertProfile, getProfileLegalDocsHandler, setUserProfile } = useAppStore(
    store => ({
      setUserProfile: store.setUserProfile,
      setCognitoProfile: store.setCognitoProfile,
      cognitoUser: store.cognitoUser,
      expertProfile: store.expertProfile,
      getProfileLegalDocsHandler: store.getProfileLegalDocsHandler,
    }),
    shallow
  );

  const memoizedGetProfileLegalDocsHandler = useCallback(getProfileLegalDocsHandler, []);

  const userRole = expertProfile?.role?.code || cognitoUser?.roles?.[0];

  const userRoles = {
    isRoleAdmin: userRole === USER_ROLES.ROLE_ADMIN,
    isRolePracticeAdmin: userRole === USER_ROLES.ROLE_PRACTICE_ADMIN,
    isRolePracticeUser: [USER_ROLES.ROLE_PRACTICE_DOCTOR].includes(userRole),
    isRoleAItester: userRole === USER_ROLES.ROLE_AI_TESTER,
  };

  const personId = expertProfile?.person?.id;
  const practiceId = expertProfile?.practice?.id;

  return {
    setCognitoProfile,
    cognitoUser,
    personId,
    userRoles,
    expertProfile,
    userRole,
    getProfileLegalDocsHandler: memoizedGetProfileLegalDocsHandler,
    setUserProfile,
    practiceId,
  };
};
