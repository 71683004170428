import App from 'App/App';
import { Amplify } from 'aws-amplify';
import { AWSconfig } from 'lib/AWS_Cognito';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

Amplify.configure(AWSconfig);

const root = createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
