import { FC, useState } from 'react';

import { Box, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { updatePassword } from 'aws-amplify/auth';

import MDButton from 'components/MDButton';
import { MDInputPassword } from 'components/MDInput';
import { ToastType, notice } from 'components/ToastNotification';
import { ValidationChecklist, changePasswordValidationData } from 'components/ValidationChecklist';
import { awsCognitoErrorHandler } from 'utils/errorHanders';

const initialState = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export const ChangePassword: FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const onFormSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setIsLoading(true);

      await updatePassword({
        oldPassword: passwordsState.currentPassword,
        newPassword: passwordsState.confirmPassword,
      });

      setPasswordsState(initialState);
      notice(ToastType.SUCCESS, 'Successfully changed password!');
    } catch (error: unknown) {
      console.error(error);
      if ((error as Error)?.message === 'Incorrect username or password.') {
        notice(ToastType.ERROR, 'Incorrect current password');
      } else {
        awsCognitoErrorHandler({ error, customErrorMessage: 'Failed to change password, please try again!' });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const [passwordsState, setPasswordsState] = useState(initialState);

  const changePasswordsState = ({
    fieldName,
    value,
  }: {
    fieldName: keyof typeof initialState;
    value: string | boolean;
  }) =>
    setPasswordsState(prevState => ({
      ...prevState,
      [fieldName]: value,
    }));

  const onChangeCurrentPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    changePasswordsState({ fieldName: 'currentPassword', value: event.target.value });
  };

  const onChangeNewPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    changePasswordsState({ fieldName: 'newPassword', value: event.target.value });
  };

  const onChangeConfirmPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    changePasswordsState({ fieldName: 'confirmPassword', value: event.target.value });
  };

  const { isValid, rules } = changePasswordValidationData(
    passwordsState.currentPassword,
    passwordsState.newPassword,
    passwordsState.confirmPassword
  );

  return (
    <Card>
      <CardHeader titleTypographyProps={{ component: 'h3' }} title="Change password" />
      <CardContent sx={{ height: 1 }}>
        <Box component="form" onSubmit={onFormSubmitHandler}>
          <Stack spacing={2}>
            <MDInputPassword
              value={passwordsState.currentPassword}
              onChange={onChangeCurrentPasswordHandler}
              label="Current password"
              fullWidth
            />

            <MDInputPassword
              value={passwordsState.newPassword}
              onChange={onChangeNewPasswordHandler}
              label="New password"
              fullWidth
            />

            <MDInputPassword
              value={passwordsState.confirmPassword}
              onChange={onChangeConfirmPasswordHandler}
              label="Confirm password"
              fullWidth
            />
          </Stack>

          <Box mt={2} mb={1}>
            <Typography variant="h3" fontSize={20}>
              Password requirements
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="body2" color="text.secondary">
              Please follow this guide for a strong password:
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
            <Box component="ul" m={0} pl={1} mb={{ xs: 1.5, sm: 0 }}>
              <ValidationChecklist validationRules={rules} />
            </Box>
            <Box ml="auto" sx={{ width: { xs: 1, sm: 'auto' } }}>
              <MDButton fullWidth disabled={isLoading || !isValid} isLoading={isLoading} size="small" type="submit">
                Update password
              </MDButton>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
