import { FC, memo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { GetPatientsParams, createPatient } from 'apiServices';
import { Resolver, useForm } from 'react-hook-form';

import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFAutocompleteField, RHFDatePicker, RHFPhoneTextField, RHFTextField } from 'components/HookForm';
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper';
import { Scrollbar } from 'components/ScrollBar';
import { ToastType, notice } from 'components/ToastNotification';
import { useOptions } from 'hooks/useOptions';
import { useUserProfile } from 'hooks/useUserProfile';
import { formErrorHandler } from 'utils/errorHanders';
import { dateToCustomFormat } from 'utils/helpers';

import { CreatePatientFormSchema, createPatientValidationSchema, patientFormDefaultValues } from './form.config';

type AddPatientDialogWindowFormProps = {
  onCloseDialogHandler: VoidFunction;
  isPracticeField?: boolean;
  onGetPatientHandler: (data: { params?: GetPatientsParams; isDefaultPage?: boolean }) => Promise<void>;
};

export const AddPatientDialogWindowForm: FC<AddPatientDialogWindowFormProps> = memo(
  ({ onGetPatientHandler, isPracticeField, onCloseDialogHandler }) => {
    const {
      userRoles: { isRoleAdmin },
      expertProfile,
    } = useUserProfile();

    const {
      handleSubmit,
      setError,
      register,
      control,
      formState: { isValid, isSubmitting, errors },
    } = useForm<CreatePatientFormSchema>({
      resolver: yupResolver(createPatientValidationSchema) as Resolver<CreatePatientFormSchema>,
      mode: 'onTouched',
      defaultValues: {
        ...patientFormDefaultValues,
        ...(!isRoleAdmin && {
          practiceId: expertProfile?.practice?.id || '',
        }),
      },
    });

    const onFormSubmitHandler = handleSubmit(async formData => {
      const { firstName, lastName, middleName, email, phone, rag, birthDate, practiceId } = formData;

      try {
        await createPatient({
          birthDate: dateToCustomFormat(birthDate),
          contact: {
            email: email || null,
            phone: `+${phone}` || null,
          },
          ...(rag && {
            patientCard: {
              rag: { data: rag },
            },
          }),
          person: { firstName, lastName, middleName: middleName || null },
          practiceId,
        });

        notice(ToastType.SUCCESS, 'Patient has been successfully created!');
        onCloseDialogHandler();

        await onGetPatientHandler({ isDefaultPage: true, params: { page: 1 } });
      } catch (error) {
        console.error(error);
        formErrorHandler({ error, config: { formError: { setError } } });
      }
    });

    const isSubmitButtonDisabled = !isValid || isSubmitting;

    const { debouncedPracticeSearchValue, practicesState, onChangePracticeInputValueHandler, fetchPractices } =
      useOptions({});

    return (
      <FormDialogWindowContent
        onFormSubmitHandler={onFormSubmitHandler}
        headerProps={{ title: 'Add Patient', onClickCancelButtonHandler: onCloseDialogHandler }}
        actionProps={{
          approveButtonProps: { disabled: isSubmitButtonDisabled, isLoading: isSubmitting },
        }}
      >
        <Scrollbar sx={{ maxHeight: 380, height: 1 }}>
          <Stack spacing={2} height={1} width={1} pb={1}>
            <RHFTextField
              register={register}
              registerName="firstName"
              registerErrors={errors?.firstName?.message}
              fullWidth
              required
              label="First name"
              placeholder="Enter First Name"
            />

            <RHFTextField
              register={register}
              registerName="middleName"
              registerErrors={errors?.middleName?.message}
              fullWidth
              label="Middle name"
              placeholder="Enter Middle Name"
            />

            <RHFTextField
              register={register}
              registerName="lastName"
              registerErrors={errors?.lastName?.message}
              fullWidth
              label="Last name"
              required
              placeholder="Enter Last Name"
            />

            <RHFDatePicker
              label="Date of Birth"
              isRequired
              control={control}
              name="birthDate"
              isDisableFuture
              placeholder="Select Date of Birth"
              sx={{
                '.MuiTypography-root': {
                  textTransform: 'none',
                },
              }}
            />

            <RHFPhoneTextField
              control={control}
              registerErrors={errors}
              registerName="phone"
              country="us"
              label="Phone number"
              MUITextFieldProps={{ fullWidth: true, sx: { width: 1 } }}
              placeholder="Enter Phone Number"
            />

            <RHFTextField
              register={register}
              registerName="email"
              registerErrors={errors?.email?.message}
              fullWidth
              label="Email"
              placeholder="Enter Email"
            />

            <RHFTextField
              register={register}
              registerName="rag"
              registerErrors={errors?.rag?.message}
              fullWidth
              multiline
              maxRows={5}
              label="Patient RAG"
              placeholder="Enter Patient RAG"
            />

            {isPracticeField && (
              <InfiniteScrollWrapper
                searchQuery={debouncedPracticeSearchValue}
                totalPages={practicesState.totalPages}
                fetchItemsHandler={fetchPractices}
              >
                {({ ref }) => (
                  <RHFAutocompleteField
                    control={control}
                    name="practiceId"
                    isRequired
                    label="Practice"
                    placeholder="Select Practice"
                    options={practicesState.items}
                    onInputChange={onChangePracticeInputValueHandler}
                    loading={practicesState.isLoading}
                    valueKey="id"
                    labelKey="name"
                    lastElementRef={ref}
                  />
                )}
              </InfiniteScrollWrapper>
            )}
          </Stack>
        </Scrollbar>
      </FormDialogWindowContent>
    );
  }
);
