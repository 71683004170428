import { FC, memo } from 'react';

import { Stack } from '@mui/material';

import { FormActions } from 'components/Form';
import { MDInput } from 'components/MDInput';
import { Scrollbar } from 'components/ScrollBar';
import { Note } from 'components/Widgets/Note';

import { RelatedConcernDetailsSection } from './RelatedConcernDetailsSection';

export const EditRelatedConcernSideMenuContent: FC = memo(() => {
  return (
    <Scrollbar>
      <Stack component="form">
        <Stack gap={2} p={{ xs: 2, sm: 3 }}>
          <RelatedConcernDetailsSection />

          <MDInput
            label="Summary"
            multiline
            rows={8}
            value="Aesthetic Results: Achieve a harmonious and natural appearance in the upper third of the face, addressing client concerns such as wrinkle reduction, brow lifting, or symmetry correction.
    Functionality: Preserve or enhance facial expressions without creating a sense of stiffness or limiting mobility.
    Safety of Procedure: Employ modern techniques and technologies to minimize risks and side effects, such as bruising, swelling, or infection.
    Comfortable Recovery: Ensure a swift and smooth recovery process, with clear guidance to help clients return to their routine quickly.
    Emotional Confidence: Instill confidence in clients regarding their decision, providing psychological support before and after the procedure.
    Personalized Approach: Develop a tailored surgical plan considering individual anatomy, age, gender, and personal preferences.
    Long-Lasting Results: Deliver outcomes that are durable over time, accounting for the natural aging process."
          />

          <MDInput
            label="Follow-up Question"
            multiline
            rows={5}
            value="Do you have any concerns about the procedure? Are there any specific areas you would like us to focus on during the follow-up?"
          />

          <Note
            note="Patient expressed satisfaction with the initial consultation and is looking forward to the procedure."
            noteDate="2023-10-01"
            noteAuthor="Dr. Smith"
            sx={{ maxWidth: 1 }}
          />
        </Stack>

        <FormActions
          isDisabled={false}
          isLoading={false}
          onFormSubmitHandler={async () => {}}
          isDirtyForm={true}
          backRoute="auto"
          sx={{
            '.MuiStack-root': {
              justifyContent: 'space-between',
            },
          }}
        />
      </Stack>
    </Scrollbar>
  );
});
