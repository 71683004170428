import { FC } from 'react';

import { Stack } from '@mui/material';

import { Icon } from 'components/Icon';
import { dateToCustomFormat } from 'utils/helpers';

import { DetailItem } from '../../../../../components';

type RelatedConcernDetailsSectionProps = {};

export const RelatedConcernDetailsSection: FC<RelatedConcernDetailsSectionProps> = () => {
  const formattedDate = dateToCustomFormat('12-20-2024');

  return (
    <Stack>
      <DetailItem icon={<Icon type="calendar" />} label="Date" value={formattedDate} />

      <DetailItem icon={<Icon type="userIcon" />} label="Provider" value="Dr. Michael Lee" />
    </Stack>
  );
};
