import { FC, memo, useEffect } from 'react';

import { Box, Divider, InputAdornment, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { RHFAutocompleteField, RHFPhoneTextField, RHFTextField } from 'components/HookForm';
import { Icon } from 'components/Icon';
import { Scrollbar } from 'components/ScrollBar';
import { usePracticeEnums } from 'hooks/usePracticeEnums';

import { EditUserFormSchema } from '../form.config';

type UserFormProps = {
  isRoleField: boolean;
};

export const UserForm: FC<UserFormProps> = memo(({ isRoleField }) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<EditUserFormSchema>();

  const {
    practicePersonTitlesEnum,
    fetchPracticePersonTitlesEnum,
    isPracticePersonTitlesEnumLoading,
    practiceRolesEnum,
    fetchPracticeRolesEnum,
    isPracticeRolesEnumLoading,
  } = usePracticeEnums();

  const userRoles = practiceRolesEnum.slice(3);

  useEffect(() => {
    fetchPracticePersonTitlesEnum();
    fetchPracticeRolesEnum();
  }, []);

  return (
    <Stack
      width={1}
      height={1}
      overflow="auto"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <Scrollbar sx={{ height: 1 }}>
        <Stack height={1}>
          <Box sx={{ px: { xs: 2, sm: 3, md: 3.5, lg: 4 }, py: { xs: 2, sm: 3, md: 3.5, lg: 4 } }}>
            <Stack spacing={2.5}>
              <Stack direction={{ xs: 'column', md: 'row' }} gap={2} alignItems="center">
                <RHFTextField
                  register={register}
                  registerName="person.firstName"
                  registerErrors={errors.person?.firstName?.message}
                  fullWidth
                  required
                  label="First name"
                  placeholder="Enter first name"
                  fieldWrapperProps={{ width: 1 }}
                />

                <RHFTextField
                  register={register}
                  registerName="person.middleName"
                  registerErrors={errors?.person?.middleName?.message}
                  fullWidth
                  label="Middle name"
                  placeholder="Enter middle name"
                  fieldWrapperProps={{ width: 1 }}
                />

                <RHFTextField
                  register={register}
                  registerName="person.lastName"
                  registerErrors={errors?.person?.lastName?.message}
                  fullWidth
                  required
                  label="Last name"
                  placeholder="Enter last name"
                  fieldWrapperProps={{ width: 1 }}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} alignItems="center">
                <RHFTextField
                  register={register}
                  registerName="person.position"
                  registerErrors={errors?.person?.position?.message}
                  fullWidth
                  label="Position"
                  placeholder="Enter position"
                  fieldWrapperProps={{ width: 1 }}
                />

                <RHFAutocompleteField
                  control={control}
                  name="person.title"
                  placeholder="Choose title"
                  label="Title"
                  options={practicePersonTitlesEnum}
                  loading={isPracticePersonTitlesEnumLoading}
                  valueKey="value"
                  labelKey="value"
                />

                <RHFAutocompleteField
                  control={control}
                  name="role"
                  isRequired
                  placeholder="Choose role"
                  options={userRoles}
                  label="Role"
                  disabled={!isRoleField}
                  loading={isPracticeRolesEnumLoading}
                  valueKey="value"
                  labelKey="value"
                />
              </Stack>
            </Stack>
          </Box>
          <Divider />
        </Stack>

        <Stack>
          <Box
            sx={{
              px: { xs: 2, sm: 3, md: 3.5 },
              py: { xs: 2, sm: 3, md: 3.5 },
            }}
          >
            <Stack spacing={2}>
              <RHFTextField
                register={register}
                registerName="person.contact.email"
                registerErrors={errors?.person?.contact?.email?.message}
                fullWidth
                required
                label="Email"
                placeholder="Enter email"
                fieldWrapperProps={{ width: { xs: 1, md: '66%' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: 0 }}>
                      <Icon type="email" />
                    </InputAdornment>
                  ),
                }}
              />

              <RHFTextField
                register={register}
                registerName="person.contact.website"
                registerErrors={errors?.person?.firstName?.message}
                fullWidth
                label="Website"
                placeholder="Enter website"
                fieldWrapperProps={{ width: { xs: 1, md: '66%' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: 0 }}>
                      <Icon type="website" />
                    </InputAdornment>
                  ),
                }}
              />

              <Stack
                maxWidth={{
                  xs: 1,
                  md: '66%',
                }}
                sx={{
                  '& .country-list': {
                    bottom: 0,
                    top: 'unset !important',
                  },
                }}
              >
                <RHFPhoneTextField
                  control={control}
                  registerErrors={errors.person}
                  registerName="person.contact.phone"
                  country="us"
                  label="Phone number"
                  MUITextFieldProps={{ fullWidth: true, sx: { width: 1 } }}
                  placeholder="Enter phone number"
                />
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Scrollbar>
    </Stack>
  );
});
