import { FC } from 'react';

import { Divider, Stack, Typography } from '@mui/material';

type FormHeaderProps = {
  title: string;
  subtitle?: string;
  actionButton?: React.ReactNode;
  isDivider?: boolean;
};
// TODO remove component
export const FormHeader: FC<FormHeaderProps> = ({ isDivider = true, title, subtitle, actionButton }) => {
  return (
    <Stack>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        justifyContent="space-between"
        spacing={1}
        width={1}
        sx={{
          '& button': {
            alignSelf: 'center',
          },
        }}
      >
        <Stack>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="subtitle2" sx={{ color: theme => theme.palette.grey[600] }}>
              {subtitle}
            </Typography>
          )}
        </Stack>
        {actionButton}
      </Stack>
      {isDivider && <Divider sx={{ my: 2 }} />}
    </Stack>
  );
};
