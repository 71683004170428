import { apiClient } from 'lib/api';

import {
  CreatePLBAalbumPayload,
  PLbeforeAfterAlbum,
  PLbeforeAfterAlbumSearchParams,
  UpdatePLBAalbumPayload,
} from './types';

const BA_PL_ALBUMS_API_URL = '/practice/b_a_albums';

export const get_pl_ba_albums = async (params: PLbeforeAfterAlbumSearchParams) =>
  await apiClient.get<ListOfItems<PLbeforeAfterAlbum>>(BA_PL_ALBUMS_API_URL, { params });

export const create_pl_ba_album = async (payload: CreatePLBAalbumPayload) =>
  await apiClient.post<PLbeforeAfterAlbum>(BA_PL_ALBUMS_API_URL, payload);

export const get_pl_ba_album = async (id: string, params: { includeOriginal: boolean }) =>
  await apiClient.get<PLbeforeAfterAlbum>(`${BA_PL_ALBUMS_API_URL}/${id}`, { params });

export const update_pl_ba_album = async (id: string, payload: UpdatePLBAalbumPayload) =>
  await apiClient.patch<PLbeforeAfterAlbum>(`${BA_PL_ALBUMS_API_URL}/${id}`, payload);

export const delete_pl_ba_album = async (id: string) => await apiClient.delete(`${BA_PL_ALBUMS_API_URL}/${id}`);

export const deactivate_pl_ba_album = async (id: string) =>
  await apiClient.delete<PLbeforeAfterAlbum>(`${BA_PL_ALBUMS_API_URL}/${id}/deactivate`);

export const activate_pl_ba_album = async (id: string) =>
  await apiClient.patch<PLbeforeAfterAlbum>(`${BA_PL_ALBUMS_API_URL}/${id}/activate`);

export const upload_pl_ba_album_cover = async (albumId: string, payload: FormData) =>
  await apiClient.post<PLbeforeAfterAlbum>(`${BA_PL_ALBUMS_API_URL}/${albumId}/cover`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const delete_pl_ba_album_cover = async (albumId: string) =>
  await apiClient.delete<PLbeforeAfterAlbum>(`${BA_PL_ALBUMS_API_URL}/${albumId}/cover`);
