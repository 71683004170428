import { FC, memo } from 'react';

import { Box, Divider, Grid, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { FormHeader } from 'components/Form';
import { RHFAutocompleteField } from 'components/HookForm';

import { Diagnosis } from '../../../../TabContentComponents';
import { LIFESTYLE_ID } from '../../constants';
import { LifestyleCardProps } from './types';

export const LifestyleCard: FC<LifestyleCardProps> = memo(
  ({ cardRef, smoking, isAllPatientEnumsLoading, alcoholConsumption }) => {
    const {
      register,
      formState: { errors },
      control,
    } = useFormContext<any>();

    return (
      <>
        <Stack ref={cardRef} id={LIFESTYLE_ID} sx={{ p: { xs: 2, sm: 2.5, md: 3, lg: 3.5 } }}>
          <FormHeader isDivider={false} title="Lifestyle" />
          <Box mt={1.5}>
            <Grid container spacing={2} direction="column">
              <Grid container item spacing={2}>
                <Grid item xs={12} lg={10}>
                  <RHFAutocompleteField
                    label="Smoking"
                    control={control}
                    name="patientCard.smoking"
                    placeholder="Select Smoking Frequency"
                    loading={isAllPatientEnumsLoading}
                    options={smoking}
                    valueKey="value"
                    labelKey="name"
                  />
                </Grid>

                <Grid item xs={12} lg={10}>
                  <RHFAutocompleteField
                    label="Alcohol Consumption"
                    control={control}
                    name="patientCard.alcoholConsumption"
                    placeholder="Select Alcohol Consumption Frequency"
                    options={alcoholConsumption}
                    loading={isAllPatientEnumsLoading}
                    valueKey="value"
                    labelKey="value"
                  />
                  {/*<Note*/}
                  {/*  noteAuthor={'Michael Lee'}*/}
                  {/*  noteDate={'Jul 1 at 2:37 pm'}*/}
                  {/*  note={'Lorem ipsum dolor sit amet'}*/}
                  {/*  sx={{ mt: 2, maxWidth: 'auto' }}*/}
                  {/*/>*/}
                </Grid>
                {/*  TODO: BE not ready*/}
                {/*  <Grid item xs={12} lg={10}>*/}
                {/*    <RHFAutocompleteField*/}
                {/*      label="Physical Activity"*/}
                {/*      control={control}*/}
                {/*      name="patientCard.physicalActivity"*/}
                {/*      placeholder="Select your physical activity level"*/}
                {/*      options={[]}*/}
                {/*      getOptionLabel={() => ''}*/}
                {/*      isOptionEqualToValue={() => false}*/}
                {/*      loading={false}*/}
                {/*    />*/}
                {/*  </Grid>*/}
                {/*  <Grid container item>*/}
                {/*    <Grid item xs={12} lg={8}>*/}
                {/*      <Typography fontWeight={400} color="text.black" variant="caption">*/}
                {/*        Drug Use*/}
                {/*      </Typography>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={12} lg={10} display="flex" justifyContent="space-between" alignItems="center">*/}
                {/*      <Typography fontSize={12} color="text.secondary">*/}
                {/*        Add Drug*/}
                {/*      </Typography>*/}
                {/*      <Icon type="add" />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={12} lg={10}>*/}
                {/*      <Diagnosis*/}
                {/*        title={'Marijuana'}*/}
                {/*        hardness={2}*/}
                {/*        notes={[*/}
                {/*          {*/}
                {/*            noteAuthor: 'Michael Lee',*/}
                {/*            noteDate: 'Jul 1 at 2:37 pm',*/}
                {/*            note: 'For example, symptoms include sneezing and itchy nose. I avoid outdoor activities during peak pollen season',*/}
                {/*          },*/}
                {/*        ]}*/}
                {/*        mt={10}*/}
                {/*      />*/}
                {/*      <Diagnosis*/}
                {/*        title={'Coke'}*/}
                {/*        hardness={1}*/}
                {/*        notes={[*/}
                {/*          {*/}
                {/*            noteAuthor: 'Michael Lee',*/}
                {/*            noteDate: 'Jul 1 at 2:37 pm',*/}
                {/*            note: 'For example, symptoms include sneezing and itchy nose. I avoid outdoor activities during peak pollen season',*/}
                {/*          },*/}
                {/*        ]}*/}
                {/*        mt={10}*/}
                {/*      />*/}
                {/*    </Grid>*/}
                {/*  </Grid>*/}
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <Divider />
      </>
    );
  }
);
