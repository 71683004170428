import { FC, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth';
import { BackButton, IllustrationLayout } from 'layouts/IllustrationLayout';
import { useAppStore } from 'store';

import MDButton from 'components/MDButton';
import { MDInput, MDInputPassword } from 'components/MDInput';
import { ToastType, notice } from 'components/ToastNotification';
import { ValidationChecklist, resetPasswordValidationData } from 'components/ValidationChecklist';
import { ROUTES } from 'constants/routes';
import { useCountdown, useRouter } from 'hooks';
import { awsCognitoErrorHandler } from 'utils/errorHanders';
import { formatCountdownTime } from 'utils/helpers';

const initialState = {
  confirmationCode: '',
  newPassword: '',
  confirmPassword: '',
};

export const ResetPassword: FC = () => {
  const { state, navigate } = useRouter();

  const recentlyLoggedInUsers = useAppStore(store => store.recentlyLoggedInUsers);

  const hiddenEmail = state?.emailHidden || '';
  const routerEmail = state?.email || '';

  const [isLoading, setIsLoading] = useState(false);

  const onFormSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setIsLoading(true);

      await confirmResetPassword({
        confirmationCode: resetPasswordsState.confirmationCode,
        username: routerEmail,
        newPassword: resetPasswordsState.confirmPassword,
      });
      notice(ToastType.SUCCESS, 'Password successfully changed!');
      navigate(recentlyLoggedInUsers.length ? ROUTES.recentlyLoggedInUsers : ROUTES.signIn, { replace: true });
    } catch (error) {
      console.error(error);
      awsCognitoErrorHandler({
        error,
        customErrorMessage: 'Failed to change password, please try again!',
        errorTypeHandler: {
          CodeMismatchException: err => {
            updateResetPasswordsState({ fieldName: 'confirmationCode', value: '' });
            notice(ToastType.ERROR, err?.message);
          },
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [resetPasswordsState, setResetPasswordsState] = useState(initialState);

  const updateResetPasswordsState = ({ fieldName, value }: { fieldName: keyof typeof initialState; value: string }) =>
    setResetPasswordsState(prevState => ({
      ...prevState,
      [fieldName]: value,
    }));

  const onChangeNewPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateResetPasswordsState({ fieldName: 'newPassword', value: event.target.value });
  };

  const onChangeCodeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value.length <= 6) {
      updateResetPasswordsState({ fieldName: 'confirmationCode', value });
    }
  };

  const onChangeConfirmPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateResetPasswordsState({ fieldName: 'confirmPassword', value: event.target.value });
  };

  const { isValid, rules } = resetPasswordValidationData(
    resetPasswordsState.confirmationCode,
    resetPasswordsState.newPassword,
    resetPasswordsState.confirmPassword
  );

  const { isRunning, countdown, startCountdown } = useCountdown({ seconds: 60, isPersist: true });

  const onResendCodeHandler = async () => {
    try {
      await resetPassword({ username: routerEmail });
      startCountdown();
      updateResetPasswordsState({ fieldName: 'confirmationCode', value: '' });
      notice(ToastType.SUCCESS, 'Reset password code has been sent to your email, please check your inbox!');
    } catch (error) {
      console.error(error);
      awsCognitoErrorHandler({ error });
    }
  };

  return (
    <IllustrationLayout
      title="Set password"
      description={`Please enter the verification code sent to your email ${hiddenEmail} and set your new password`}
      backButton={<BackButton title="Back to Forgot password" navigateTo={ROUTES.forgotPassword} />}
    >
      <Box component="form" display="flex" flexDirection="column" gap={2.5} onSubmit={onFormSubmitHandler}>
        <Stack spacing={0.5}>
          <MDInput
            label="Verification code"
            placeholder="Enter code"
            value={resetPasswordsState.confirmationCode}
            onChange={onChangeCodeHandler}
            fullWidth
            name="code"
            type="number"
            inputProps={{
              autoComplete: 'off',
            }}
            InputProps={{
              autoComplete: 'new-password',
            }}
          />

          <Typography variant="button" color="grey.600" fontWeight={400} component="p">
            Don`t see it?{' '}
            {!isRunning ? (
              <Typography
                sx={{ ':hover': { cursor: 'pointer' } }}
                variant="button"
                color="primary.main"
                component="span"
                onClick={onResendCodeHandler}
              >
                Resend it
              </Typography>
            ) : (
              <Typography variant="button" color="inherit" fontWeight={400}>
                Send a new code in{' '}
                <Box component="span" color="grey.500">
                  {formatCountdownTime(countdown)}
                </Box>
              </Typography>
            )}
          </Typography>
        </Stack>

        <MDInputPassword
          value={resetPasswordsState.newPassword}
          onChange={onChangeNewPasswordHandler}
          placeholder="Enter password"
          label="Password"
          fullWidth
          inputProps={{
            autoComplete: 'off',
          }}
          InputProps={{
            autoComplete: 'new-password',
          }}
        />

        <MDInputPassword
          value={resetPasswordsState.confirmPassword}
          onChange={onChangeConfirmPasswordHandler}
          label="Confirm password"
          placeholder="Confirm new password"
          fullWidth
          inputProps={{
            autoComplete: 'off',
          }}
          InputProps={{
            autoComplete: 'new-password',
          }}
        />

        <ValidationChecklist validationRules={rules} />

        <MDButton type="submit" fullWidth isLoading={isLoading} disabled={isLoading || !isValid}>
          Set password
        </MDButton>
      </Box>
    </IllustrationLayout>
  );
};
