import { forwardRef } from 'react';

import { Stack, StackProps, TextFieldProps, TextFieldVariants, Typography } from '@mui/material';

import { FormHelperText } from 'components/Form';
import MDInputRoot, { TextFieldOwnerState } from 'components/MDInput/MDInputRoot';

import { LoadingAdornment } from './LoadingAdornment';

export interface MDInputProps extends Omit<TextFieldProps, 'variant'>, TextFieldOwnerState {
  isLoading?: boolean;
  variant?: TextFieldVariants;
  fieldWrapperProps?: StackProps;
}

export const MDInput = forwardRef<HTMLDivElement, MDInputProps>(
  (
    {
      fieldWrapperProps,
      label,
      variant = 'standard',
      required,
      helperText,
      name,
      disabled,
      InputProps,
      isLoading,
      ...rest
    },
    ref
  ) => {
    return (
      <Stack spacing={0.5} {...fieldWrapperProps}>
        {label && (
          <Typography
            textTransform="capitalize"
            textAlign="left"
            component="label"
            htmlFor={name}
            variant="caption"
            fontWeight={400}
          >
            {label}
            {required && (
              <Typography variant="caption" color="error.main" fontSize={13}>
                *
              </Typography>
            )}
          </Typography>
        )}

        <MDInputRoot
          {...rest}
          ref={ref}
          name={name}
          id={name}
          required={required}
          variant={variant}
          disabled={disabled}
          ownerState={{ disabled }}
          InputProps={{
            ...InputProps,
            ...((isLoading || InputProps?.endAdornment) && {
              endAdornment: (
                <>
                  {isLoading ? (
                    <LoadingAdornment circularProgessProps={{ size: 20 }} inputAdornmentProps={{ position: 'end' }} />
                  ) : null}
                  {InputProps?.endAdornment}
                </>
              ),
            }),
          }}
          helperText={helperText ? <FormHelperText text={helperText || ''} /> : null}
        />
      </Stack>
    );
  }
);
