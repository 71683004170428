import { FC, useMemo, useState } from 'react';

import { Box, FormControlLabel, Stack, Switch } from '@mui/material';

import { FilterMenuPopoverButtons } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import { getFilterApplyButtonStatus, getFilterResetButtonStatus } from 'utils/helpers';

import { FiltersInitialState, FiltersMenuContentProps } from '../types';
import { FiltersInitialStateValue } from '../usePractices.state';

export const FiltersMenuContent: FC<FiltersMenuContentProps> = ({
  appliedFilters,
  setAppliedFilters,
  closeMenuHandler,
}) => {
  const [filters, setFilters] = useState<FiltersInitialState>(appliedFilters);

  const onChangeSwitchHandler = (_: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    setFilters(prev => ({ ...prev, isShowDeactivated: checked }));
  };

  const onClickResetFiltersButtonHandler = () => setFilters(FiltersInitialStateValue);

  const onClickApplyFiltersButtonHandler = async () => {
    setAppliedFilters(filters);
    closeMenuHandler();
  };

  const filterEntries = Object.entries(filters);

  const isApplyButtonDisabled = useMemo(
    () => getFilterApplyButtonStatus({ appliedFilters, filterEntries }),
    [filters, appliedFilters]
  );

  const isResetButtonDisabled = useMemo(
    () => getFilterResetButtonStatus({ filterEntries, defaultFilters: FiltersInitialStateValue }),
    [filters]
  );

  return (
    <Stack position="relative">
      <Scrollbar style={{ maxHeight: 410 }}>
        <Stack height={1} spacing={2} py={1}>
          <Box>
            <FormControlLabel
              onChange={onChangeSwitchHandler}
              checked={filters.isShowDeactivated}
              control={<Switch />}
              label="Show deactivated"
              labelPlacement="start"
            />
          </Box>
        </Stack>
      </Scrollbar>
      <FilterMenuPopoverButtons
        isClearButtonDisabled={isResetButtonDisabled}
        onClickClearButtonHandler={onClickResetFiltersButtonHandler}
        isApplyButtonDisabled={isApplyButtonDisabled}
        onClickApplyButtonHandler={onClickApplyFiltersButtonHandler}
      />
    </Stack>
  );
};
