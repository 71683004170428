import { FC, memo } from 'react';

import { Box, Divider, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

import logo from 'assets/icons/aesthetics360_full_logo.svg';
import minifiedLogo from 'assets/icons/aesthetics360_minified_logo.svg';
import { Scrollbar } from 'components/ScrollBar';
import { HEADER_CONFIG } from 'constants/layout-config';
import { ROUTES } from 'constants/routes';

import { NavAccount } from './NavAccount';
import { VerticalNavSection } from './VerticalNavSection';
import { createTransition } from './helpers';

type NavVerticalRenderContentProps = {
  isNavMini: boolean;
};

export const NavVerticalRenderContent: FC<NavVerticalRenderContentProps> = memo(({ isNavMini }) => {
  return (
    <Stack
      sx={{
        height: 1,
        px: 1,
        pb: 2,
        position: 'relative',
      }}
    >
      <Stack
        spacing={3}
        sx={{
          py: 1,
          px: 1,
          flexShrink: 0,
          height: HEADER_CONFIG.H_DASHBOARD_HEADER,
          boxSizing: 'border-box',
          borderBottom: ({ borders, palette }) => `${borders.borderWidth[1]} solid ${palette.divider}`,
          justifyContent: 'center',
        }}
      >
        <Stack direction="row" justifyContent={isNavMini ? 'flex-start' : 'space-between'} alignItems="center">
          <Stack direction="row" component={Link} to={ROUTES.home} alignItems="center">
            <Box
              component="img"
              src={minifiedLogo}
              alt="A360 minified logo"
              sx={{
                width: 0,
                height: 0,
                transition: theme => createTransition(theme, 'all'),
                ...(isNavMini && { width: 32, height: 32, overflow: 'hidden' }),
              }}
            />

            <Box
              component="img"
              src={logo}
              alt="A360 logo"
              width={1}
              height={23}
              sx={{
                width: 1,
                transition: theme => createTransition(theme, 'all'),
                ...(isNavMini && { width: 0, overflow: 'hidden' }),
              }}
            />
          </Stack>
        </Stack>
      </Stack>

      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <VerticalNavSection isNavMini={isNavMini} />
      </Scrollbar>

      <Divider />

      <NavAccount isNavMini={isNavMini} />
    </Stack>
  );
});
