import React, { createContext, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { CircularProgress, Stack } from '@mui/material';
import { getExpertProfile } from 'apiServices';
import { fetchUserAttributes, getCurrentUser, signIn, signOut, signUp, verifyTOTPSetup } from 'aws-amplify/auth';
import { AxiosError } from 'axios';
import { getAuthSession } from 'lib/AWS_Cognito/helpers';
import { useIdleTimer } from 'react-idle-timer';
import { resetAllSlices } from 'store';

import { TimeoutSessionDialogWindow } from 'components/BaseDialogWindow';
import { ToastType, notice } from 'components/ToastNotification';
import { ROUTES } from 'constants/routes';
import { useBoolean } from 'hooks/useBoolean';
import { useCountdown } from 'hooks/useCountdown';
import { useRouter } from 'hooks/useRouter';
import { useUserProfile } from 'hooks/useUserProfile';
import { USER_ROLES } from 'types/enums';
import { awsCognitoErrorHandler, backendErrorHandler } from 'utils/errorHanders';

type SignInCognitoProps = {
  email: string;
  password: string;
  onTOTPRequiedHandler?: () => void;
  onSuccessfulSignInHandler?: () => void;
};

export type CognitoUserContextValue = {
  getCognitoCurrentUser: () => Promise<void>;
  signOutCognito: (isDisableNavigation?: boolean) => Promise<void>;
  getPracticeProfileAndLegalDocsHandler: () => Promise<void>;
  signInCognito: (props: SignInCognitoProps) => Promise<void>;
  signUpCognito: (email: string, password: string) => Promise<void>;
  verifyTOTP: (TOTPcode: string) => Promise<void>;
};

const Context = createContext<CognitoUserContextValue>({
  getCognitoCurrentUser: async () => {},
  getPracticeProfileAndLegalDocsHandler: async () => {},
  signOutCognito: async () => {},
  signInCognito: async () => null,
  signUpCognito: async () => {},
  verifyTOTP: async () => null,
});

const DEFAULT_IDLE_TIMEOUT_VALUE = 900000; // 15 minutes
const SESSION_COUNTDOWN_TO_LOGOUT_VALUE = 30; // 30 seconds

const exemptedRoutes: ROUTES[] = [ROUTES.forgotPassword, ROUTES.notFound, ROUTES.signIn, ROUTES.resetPassword];

export const CognitoAuthContextProvider = ({ children }: React.PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(true);

  const { navigate, pathname } = useRouter();

  const getCognitoCurrentUser = useCallback(async () => {
    try {
      const currentAuthSession = await getAuthSession();

      if (currentAuthSession?.tokens) {
        const user = await getCurrentUser();
        const userAttributes = await fetchUserAttributes();

        setCognitoProfile({
          ...user,
          userAttributes,
          roles: currentAuthSession.tokens?.accessToken?.payload?.['cognito:groups'] as USER_ROLES[],
        });
      } else {
        setCognitoProfile(null);
      }
    } catch (error) {
      awsCognitoErrorHandler({
        error,
        async customErrorHandler(err) {
          notice(ToastType.ERROR, err?.message || 'Failed to get user, please try again!');
          await signOutCognito();
        },
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getCognitoCurrentUser();
  }, []);

  const { cognitoUser, setCognitoProfile, getProfileLegalDocsHandler, setUserProfile } = useUserProfile();

  const getPracticeProfileAndLegalDocsHandler = useCallback(async (): Promise<void> => {
    try {
      const { tokens } = await getAuthSession();
      const userRoles = (tokens?.accessToken?.payload?.['cognito:groups'] as USER_ROLES[]) || [];

      const route = userRoles.includes(USER_ROLES.ROLE_AI_TESTER) ? ROUTES.promptLibrary : ROUTES.patients;

      const isUserWithProfile =
        !userRoles.includes(USER_ROLES.ROLE_ADMIN) && !userRoles.includes(USER_ROLES.ROLE_AI_TESTER);

      if (isUserWithProfile) {
        const { data } = await getExpertProfile();
        setUserProfile(data);

        await getProfileLegalDocsHandler();
      }

      notice(ToastType.SUCCESS, 'Welcome!');

      navigate(route);
    } catch (error) {
      console.error(error);
      backendErrorHandler({
        error,
        config: {
          customErrorHandler: async err => {
            if (err instanceof AxiosError) {
              const errorStatus = err?.response?.status;

              switch (errorStatus) {
                case 404:
                  notice(ToastType.ERROR, 'Practice profile not found!');
                  break;
                case 403:
                  notice(ToastType.ERROR, err?.response?.data?.detail || '');
                  break;
                default:
                  notice(ToastType.ERROR, 'Failed to get practice profile, please try again!');
                  break;
              }

              await signOutCognito();
            }
          },
        },
      });
    }
  }, []);

  const signInCognito = useCallback(
    async ({ email, password, onTOTPRequiedHandler, onSuccessfulSignInHandler }: SignInCognitoProps) => {
      try {
        const { isSignedIn, nextStep } = await signIn({ username: email, password });

        if (!isSignedIn && nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE') return onTOTPRequiedHandler();

        if (isSignedIn && nextStep.signInStep === 'DONE') {
          await getCognitoCurrentUser();
          await getPracticeProfileAndLegalDocsHandler();
          onSuccessfulSignInHandler?.();
        }
      } catch (error) {
        console.error(error);
        awsCognitoErrorHandler({ error, customErrorMessage: 'Failed to sign in.' });
      }
    },
    []
  );

  const signUpCognito = useCallback(async (email: string, password: string) => {
    try {
      await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
          },
        },
      });
    } catch (error) {
      console.error(error);
      awsCognitoErrorHandler({ error, customErrorMessage: 'Failed to sign up.' });
    }
  }, []);

  const signOutCognito = useCallback(async () => {
    try {
      await signOut();
      resetAllSlices();
      setCognitoProfile(null);
    } catch (error) {
      console.error(error);
      awsCognitoErrorHandler({ error, customErrorMessage: 'Failed to sign out.' });
    }
  }, []);

  const verifyTOTP = useCallback(async (code: string) => {
    try {
      await verifyTOTPSetup({ code });
      await getCognitoCurrentUser();
    } catch (err) {
      throw err;
    }
  }, []);

  // Timeout session logic (similar to your original code)
  // TODO put to separate context
  const [isTimeoutSessionDialogWindowOpen, openTimeoutSessionDialogWindow, closeTimeoutSessionDialogWindow] =
    useBoolean(false);

  const { countdown, startCountdown, isRunning, stopCountdown, isFinished } = useCountdown({
    seconds: SESSION_COUNTDOWN_TO_LOGOUT_VALUE,
  });

  const onIdle = useCallback(() => {
    openTimeoutSessionDialogWindow();
    if (document.visibilityState === 'visible') {
      startCountdown();
    }
  }, [openTimeoutSessionDialogWindow, startCountdown]);

  const { start, pause } = useIdleTimer({
    onIdle,
    timeout: DEFAULT_IDLE_TIMEOUT_VALUE,
    throttle: 250,
    crossTab: true,
    name: 'authSessionContext',
    startManually: true,
    stopOnIdle: true,
  });

  useEffect(() => {
    if (exemptedRoutes.includes(pathname as ROUTES) || !cognitoUser) return;
    // start();
  }, [pathname]);

  useEffect(() => {
    // if (isFinished) onClickLogoutButton();
  }, [isFinished]);

  useLayoutEffect(() => {
    if (exemptedRoutes.includes(pathname as ROUTES) || !cognitoUser) return;

    const handlerVisibility = () => {
      if (document.visibilityState === 'visible' && !isRunning) {
        document.visibilityState === 'visible' && startCountdown();
      }
    };

    document.addEventListener('visibilitychange', handlerVisibility);

    return () => document.removeEventListener('visibilitychange', handlerVisibility);
  }, [pathname, isRunning]);

  const onClickLogoutButton = useCallback(() => {
    closeTimeoutSessionDialogWindow();
    pause();
    stopCountdown();
    signOutCognito();
  }, []);

  const onClickStayLoggedInButton = useCallback(() => {
    start();
    closeTimeoutSessionDialogWindow();
    stopCountdown();
  }, []);

  const contextValue = useMemo(
    () => ({
      getCognitoCurrentUser,
      signOutCognito,
      signInCognito,
      signUpCognito,
      verifyTOTP,
      getPracticeProfileAndLegalDocsHandler,
    }),
    [
      getPracticeProfileAndLegalDocsHandler,
      getCognitoCurrentUser,
      signInCognito,
      signOutCognito,
      signUpCognito,
      verifyTOTP,
    ]
  );

  return (
    <Context.Provider value={contextValue}>
      {isLoading ? (
        <Stack
          sx={{
            justifyContent: 'center',
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: 1,
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        children
      )}

      <TimeoutSessionDialogWindow
        onClickApproveButtonHandler={onClickStayLoggedInButton}
        onClickCancelButtonHandler={onClickLogoutButton}
        open={isTimeoutSessionDialogWindowOpen}
        isFinished={isFinished}
        timerValue={countdown}
        startTimerValue={SESSION_COUNTDOWN_TO_LOGOUT_VALUE}
      />
    </Context.Provider>
  );
};

export const useCognitoAuthContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useCognitoAuthContext should be used inside the CognitoUserContextProvider.');
  }
  return context;
};
