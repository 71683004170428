import { AuthSession, fetchAuthSession, setUpTOTP } from 'aws-amplify/auth';

import { TOTP_APP_NAME } from 'constants/strings';
import { awsCognitoErrorHandler } from 'utils/errorHanders';

export const getAuthSession = async (): Promise<AuthSession> => {
  try {
    return await fetchAuthSession();
  } catch (error) {
    console.error(error);
    awsCognitoErrorHandler({ error, customErrorMessage: 'Failed to get auth session, please try again!' });
  }
};

export const setupTOTPHandler = async (): Promise<URL> => {
  const totpSetupDetails = await setUpTOTP();

  const setupUri = totpSetupDetails.getSetupUri(TOTP_APP_NAME);

  return setupUri;
};
