import { FC } from 'react';

import { Box, Card, Grid, Stack } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDTabs } from 'components/MDTabs';
import { EntityHeaderActions, EntityHeaderRoot } from 'components/Widgets';
import { dateToCustomFormat } from 'utils/helpers';

import { DetailItem } from '../../components';
import { GeneralTabContent } from './GeneralTabContent';
import { RelatedConcernsTabContent } from './RelatedConcernsTabContent';
import {
  PATIENT_CONCERN_PROFILE_TAB_VALUES,
  usePatientConsultationProfileState,
} from './usePatientConsultationProfile.state';

const renderTabContent = (tabValue: number) => {
  switch (tabValue) {
    case 0:
      return <GeneralTabContent />;
    case 1:
      return <RelatedConcernsTabContent />;
    default:
      return null;
  }
};

export const PatientConsultationProfile: FC = () => {
  const { handleSetTabValue, tabValue } = usePatientConsultationProfileState();

  const formattedDate = dateToCustomFormat('12-20-2024');
  return (
    <Stack component="section">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ height: 1 }}>
            <EntityHeaderRoot
              tabs={
                <Box
                  display="flex"
                  justifyContent={{ xs: 'center', sm: 'flex-start' }}
                  maxWidth={{ xs: 1, sm: 400 }}
                  width={1}
                  mt={1}
                >
                  <MDTabs
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    value={tabValue}
                    tabsData={PATIENT_CONCERN_PROFILE_TAB_VALUES}
                    handleSetTabValue={handleSetTabValue}
                  />
                </Box>
              }
            >
              <EntityHeaderActions activeStatusProps={{ isActive: true }} />
              <DetailItem icon={<Icon type="calendar" />} label="Date" value={formattedDate} />
              <DetailItem icon={<Icon type="userIcon" />} label="Provider" value="Dr. Michael Lee" />
              <DetailItem icon={<Icon type="settingsIcon" />} label="Workflow" value="Follow-up Visit" />
            </EntityHeaderRoot>
            {renderTabContent(tabValue)}
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};
