import { useEffect, useState } from 'react';

import { InView, IntersectionObserverProps } from 'react-intersection-observer';

type InfiniteScrollWrapperProps = IntersectionObserverProps & {
  totalPages: number;
  fetchItemsHandler: (params?: { page: number }) => Promise<void>;
  searchQuery?: string;
};

export const InfiniteScrollWrapper = ({
  totalPages,
  fetchItemsHandler,
  children,
  searchQuery,
  ...inViewProps
}: InfiniteScrollWrapperProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleFetchNext = () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;

      fetchItemsHandler({ page: nextPage });

      setCurrentPage(nextPage);
    }
  };

  useEffect(() => {
    if (currentPage > 1) setCurrentPage(1);
  }, [searchQuery]);

  const onChangeInViewHandler = (inView: boolean) => inView && handleFetchNext();

  return (
    <InView as="div" onChange={onChangeInViewHandler} {...inViewProps}>
      {ref => children(ref)}
    </InView>
  );
};
