import { useTabs } from 'hooks/useTabs';

export enum PATIENT_CONSULTATION_PROFILE_TABS {
  GENERAL = 'General',
  RELATED_CONCERNS = 'Related Concerns',
}
export const PATIENT_CONCERN_PROFILE_TAB_VALUES: PATIENT_CONSULTATION_PROFILE_TABS[] = [
  PATIENT_CONSULTATION_PROFILE_TABS.GENERAL,
  PATIENT_CONSULTATION_PROFILE_TABS.RELATED_CONCERNS,
];

export const usePatientConsultationProfileState = () => {
  const { tabValue, handleSetTabValue } = useTabs();

  return {
    tabValue,
    handleSetTabValue,
  };
};
