import { FC } from 'react';

import { CheckRounded, CloseRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

import { ValidationRule } from './validationRules';

type ValidationRulesProps = {
  validationRules: ValidationRule[];
};

export const ValidationChecklist: FC<ValidationRulesProps> = ({ validationRules }) => {
  return (
    <Stack>
      {validationRules.map(({ isValid, label }) => (
        <Stack
          key={label}
          direction="row"
          alignItems="center"
          spacing={0.5}
          sx={{
            color: theme => (isValid ? theme.palette.primary.main : 'inherit'),
            '& svg': {
              width: 18,
              height: 18,
            },
          }}
        >
          {isValid ? <CheckRounded fontSize="medium" /> : <CloseRounded fontSize="medium" />}
          <Typography variant="button">{label}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};
