import { FC } from 'react';

import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { LegalDocumentsDialogWindow } from 'components/BaseDialogWindow';
import { Breadcrumbs, MobileBreadCrumbs } from 'components/Breadcrumbs';
import { NavVertical } from 'components/NavVertical';

import { ContentWrapper } from './ContentWrapper';
import { MainLayoutStyles as Styled } from './MainLayout.styles';
import { useMainLayoutState } from './useMainLayout.state';

export const MainLayout: FC = () => {
  const { handleClose, handleOpen, isMobile, isNavMini, isOpenMobileNavBar, isTablet } = useMainLayoutState();
  return (
    <Box sx={Styled.BoxSX}>
      <NavVertical isTabletScreen={isTablet} onCloseSideNav={handleClose} isOpenMobileNavBar={isOpenMobileNavBar} />
      <Styled.LayoutWrapper isNavMini={isNavMini} isTablet={isTablet}>
        <Styled.Header component="header">
          {isMobile ? (
            <MobileBreadCrumbs
              isMobileScreen={isMobile}
              isOpenMobileNavBar={isOpenMobileNavBar}
              onOpenSideNav={handleOpen}
            />
          ) : (
            <Breadcrumbs isTabletScreen={isTablet} isOpenMobileNavBar={isOpenMobileNavBar} onOpenSideNav={handleOpen} />
          )}
        </Styled.Header>
        <ContentWrapper>
          <Outlet />
        </ContentWrapper>
      </Styled.LayoutWrapper>
      <LegalDocumentsDialogWindow />
    </Box>
  );
};
