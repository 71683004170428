import { Color, PaletteColor, PaletteColorOptions, TypeText } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

import { ColoredShadow } from './boxShadows';

interface ColorsTypes {
  main: string;
  focus: string;
}

interface GradientsTypes {
  main: string;
  state?: string;
}

interface BadgeColorsTypes {
  background: string;
  text: string;
}

type Background = {
  default: string;
};

type White = {
  main: string;
  focus: string;
};

type Text = {
  main: string;
  focus: string;
};

type Transparent = {
  main: string;
};

type Black = {
  light: string;
  main: string;
  focus: string;
};

type Gradients = {
  primary: GradientsTypes;
  secondary: GradientsTypes;
  info: GradientsTypes;
  success: GradientsTypes;
  warning: GradientsTypes;
  error: GradientsTypes;
  light: GradientsTypes;
  dark: GradientsTypes;
};

type BadgeColors = {
  primary: BadgeColorsTypes;
  secondary: BadgeColorsTypes;
  info: BadgeColorsTypes;
  success: BadgeColorsTypes;
  warning: BadgeColorsTypes;
  error: BadgeColorsTypes;
  light: BadgeColorsTypes;
  dark: BadgeColorsTypes;
};

interface PaletteTypes {
  background: Background;
  white: White;
  text: Text & Partial<TypeText>;
  transparent: Transparent;
  black: Black;
  primary: PaletteColor;
  secondary: PaletteColor;
  info: PaletteColor;
  success: PaletteColor;
  warning: PaletteColor;
  error: PaletteColor;
  dark: ColorsTypes;
  light: ColorsTypes;
  grey: ColorPartial;
  gradients: Gradients;
  badgeColors: BadgeColors;
  coloredShadows: ColoredShadow;
  inputBorderColor: string;
  tabs: {
    indicator: {
      boxShadow: string;
    };
  };
}

declare module '@mui/material/styles' {
  // Palette: The fully built palette object that defines the entire color scheme of your theme.
  interface PaletteColor {
    focus?: string;
  }
  interface TypeBackground {
    default: string;
  }

  interface TypeText {
    main: string;
    focus: string;
  }

  interface Palette {
    white: White;
    transparent: Transparent;
    black: Black;
    primary: PaletteColor;
    secondary: PaletteColor;
    info: PaletteColor;
    success: PaletteColor;
    warning: PaletteColor;
    error: PaletteColor;
    dark: ColorsTypes;
    light: ColorsTypes;
    grey: Color;
    gradients: Gradients;
    badgeColors: BadgeColors;
    coloredShadows: ColoredShadow;
    inputBorderColor: string;
    tabs: {
      indicator: {
        boxShadow: string;
      };
    };
  }

  // PaletteOptions: A subset of the palette that you define in themeOptions to customize colors before the theme is created.
  interface PaletteOptions {
    white: White;
    transparent: Transparent;
    black: Black;
    primary?: PaletteColorOptions;
    secondary?: PaletteColorOptions;
    info?: PaletteColorOptions;
    success?: PaletteColorOptions;
    warning?: PaletteColorOptions;
    error?: PaletteColorOptions;
    dark: PaletteColorOptions;
    light: PaletteColorOptions;
    grey?: ColorPartial;
    gradients: Gradients;
    badgeColors: BadgeColors;
    coloredShadows: ColoredShadow;
    inputBorderColor: string;
    tabs: {
      indicator: {
        boxShadow: string;
      };
    };
  }
}

const colors: PaletteTypes = {
  background: {
    default: '#f0f2f5',
  },

  text: {
    main: '#7b809a',
    focus: '#7b809a',
  },

  transparent: {
    main: 'transparent',
  },

  white: {
    main: '#ffffff',
    focus: '#ffffff',
  },

  black: {
    light: '#000000',
    main: '#000000',
    focus: '#000000',
  },

  primary: {
    main: '#547BA3',
    light: '#7597BB',
    dark: '#416288',
    contrastText: '#fff',
  },

  secondary: {
    main: '#f50057',
    light: '#F73378',
    dark: '#AB003C',
    contrastText: '#fff',
  },

  info: {
    light: '#61F3F3',
    main: '#00B8D9',
    dark: '#006C9C',
    contrastText: '#fff',
  },

  success: {
    main: '#17B26A',
    light: '#47CD89',
    dark: '#079455',
    contrastText: '#fff',
  },

  warning: {
    light: '#FDB022',
    main: '#F79009',
    dark: '#DC6803',
    contrastText: '#fff',
  },

  error: {
    main: '#F04438',
    dark: '#D92D20',
    light: '#F97066',
    contrastText: '#fff',
  },

  dark: {
    main: '#344767',
    focus: '#2c3c58',
  },

  light: {
    main: '#f0f2f5',
    focus: '#f0f2f5',
  },

  grey: {
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
  },

  gradients: {
    primary: {
      main: '#49a3f1',
      state: '#1A73E8',
    },

    secondary: {
      main: '#EC407A',
      state: '#D81B60',
    },

    info: {
      main: '#747b8a',
      state: '#495361',
    },

    success: {
      main: '#66BB6A',
      state: '#43A047',
    },

    warning: {
      main: '#FFA726',
      state: '#FB8C00',
    },

    error: {
      main: '#EF5350',
      state: '#E53935',
    },

    light: {
      main: '#EBEFF4',
      state: '#CED4DA',
    },

    dark: {
      main: '#42424a',
      state: '#191919',
    },
  },

  badgeColors: {
    primary: {
      background: '#f8b3ca',
      text: '#cc084b',
    },

    secondary: {
      background: '#d7d9e1',
      text: '#6c757d',
    },

    info: {
      background: '#aecef7',
      text: '#095bc6',
    },

    success: {
      background: '#bce2be',
      text: '#339537',
    },

    warning: {
      background: '#ffd59f',
      text: '#c87000',
    },

    error: {
      background: '#fcd3d0',
      text: '#f61200',
    },

    light: {
      background: '#ffffff',
      text: '#c7d3de',
    },

    dark: {
      background: '#8097bf',
      text: '#1e2e4a',
    },
  },

  coloredShadows: {
    primary: '#e91e62',
    secondary: '#110e0e',
    info: '#00bbd4',
    success: '#4caf4f',
    warning: '#ff9900',
    error: '#f44336',
    light: '#adb5bd',
    dark: '#404040',
  },

  inputBorderColor: '#d2d6da',

  tabs: {
    indicator: { boxShadow: '#ddd' },
  },
};

export default colors;
